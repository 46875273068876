import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'

class LoginForm extends Component {

  render () {
    const { handleSubmit } = this.props
    return (
      <div>
        <h1>Форма входу менеджера</h1>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <Field
              name="username"
              component="input"
              type="text"
              placeholder="Ім'я користувача"
              className={'form-control'}
            />
          </div>
          <div className="form-group">
            <Field
              name="password"
              component="input"
              type="password"
              placeholder="Пароль"
              className={'form-control'}
            />
          </div>
          <button type="submit" className={'btn btn-main btn-lg btn-block'}>Увійти</button>
        </form>
      </div>
    )
  }
}

LoginForm = reduxForm({
  form: 'login',
})(LoginForm)

export default LoginForm
