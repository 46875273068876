import { createStore, applyMiddleware, compose } from "redux"
// Import Combined Reducers
import rootReducer from "./reducers"
import createSagaMiddleware from "redux-saga"
import rootSaga from "./sagas/rootSaga"

const sagaMiddleware = createSagaMiddleware()
// const devToolsExtension = (window).__REDUX_DEVTOOLS_EXTENSION__ && (window).__REDUX_DEVTOOLS_EXTENSION__()
// Create Global Store
// eslint-disable-next-line
export default initialState => {
  const store = createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(sagaMiddleware)
    ),
  )
  sagaMiddleware.run(rootSaga, store.dispatch)
  return store
}
