// Import React
import React, {Fragment} from "react"
import ReactDOM from "react-dom"
import {BrowserRouter as Router, Route, Switch} from "react-router-dom"
import ScrollMemory from "react-router-scroll-memory"
import "bootstrap/dist/css/bootstrap.min.css"
import "./styles/index.scss"

// Import Redux
import {Provider} from "react-redux"
import CreateStore from "./store"

import {
    MainPage,
    AboutPage,
    DeliveryPage,
    ContactsPage,
    ProductPage,
    ControlPanel,
} from "./pages"
import NotFound from "./components/notfound"
import ControlCategories from "./pages/control/categories"
import ControlManufacturers from "./pages/control/manufacturers"
import ControlAddProduct from "./pages/control/addProduct"
import ControlEditProduct from "./pages/control/editProduct"
import ControlProductPrice from "./pages/control/priceProduct"
import Category from "./pages/category"
import Manufacturer from "./pages/manufacturers"
import LatestProducts from "./components/menu/latest"
import TopProducts from "./components/menu/top"
import StockProducts from "./components/menu/stock"

const Store = CreateStore()

class App extends React.Component {
    render() {
        return (
            <Provider store={Store}>
                <Fragment>
                    <Router>
                        <Switch>
                            <Route path="/" exact
                                   render={(props) => <MainPage {...props} />}/>
                            <Route path="/about" exact render={() =>
                                <AboutPage/>}/>
                            <Route path="/contacts" exact render={() =>
                                <ContactsPage/>}/>
                            <Route path="/delivery" exact render={() =>
                                <DeliveryPage/>}/>
                            <Route path={["/category/:name"]} exact render={(props) =>
                                <Category {...props}/>}/>
                            <Route path={["/manufacturer/:name"]} exact render={(props) =>
                                <Manufacturer {...props}/>}/>
                            <Route path="/product/:category/:manufacturers/:name" exact
                                   render={(props) => <ProductPage {...props} />}/>
                            <Route path="/latest" exact render={() =>
                                <LatestProducts/>}/>
                            <Route path="/top" exact render={() =>
                                <TopProducts/>}/>
                            <Route path="/stock" exact render={() =>
                                <StockProducts/>}/>
                            <Route path="/control-panel" exact render={() =>
                                <ControlPanel/>}/>
                            <Route path="/control-panel/categories" exact render={() =>
                                <ControlCategories/>}/>
                            <Route path="/control-panel/price" exact render={() =>
                                <ControlProductPrice/>}/>
                            <Route path="/control-panel/manufacturers" exact render={() =>
                                <ControlManufacturers/>}/>
                            <Route path="/control-panel/add-products" exact render={() =>
                                <ControlAddProduct/>}/>
                            <Route path="/control-panel/edit-products" exact render={() =>
                                <ControlEditProduct/>}/>
                            <Route component={NotFound}/>
                        </Switch>
                        {/*<Foot/>*/}
                        <ScrollMemory/>
                    </Router>
                </Fragment>
            </Provider>
        )
    }
}

debugger;

ReactDOM.render(<App/>, document.getElementById("root"))
