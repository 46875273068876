// Import React Library
import React, { Fragment } from "react"
import { connect } from "react-redux"
import Header from "../../components/header"
import MUIDataTable from "mui-datatables"
import {
  createMuiTheme,
} from "@material-ui/core/styles"
import MenuTabs from "./menu"
import { GET_PRODUCTS_REQUEST, IS_ADMIN } from "../../actions/actionCreator"
import IsAdmin from "./isAdmin"
import { isAdminAPI } from "../../api"

class ControlProductPrice extends React.Component {

  componentDidMount() {
    document.title = "Панель менеджера - Редактировать продукт"
    isAdminAPI()
      .then(result => {
        if (result.status) {
          this.props.dispatch(IS_ADMIN(true))
          this.props.dispatch(GET_PRODUCTS_REQUEST())
        }
      })
  }

  getMuiTheme = () => createMuiTheme({
    overrides: {
      MUIDataTable: {
        responsiveScroll: {
          maxHeight: "880px",
          overflowX: "hidden",
        },
      },
      Mui: {
        maxHeight: "880px",
        selected: {
          backgroundColor: "#000",
          "&:hover": {
            // backgroundColor: "#FFF",
            backgroundColor: "#ccc",
          },
        },
        checked: {
          color: "#000",
        },
      },
    },
  })

  render() {

    const { ProductsData, isAdmin } = this.props

    if (!isAdmin) return <IsAdmin/>

    if (!ProductsData) return "Завантаження..."

    // eslint-disable-next-line no-empty-pattern
    const Data = [] = ProductsData.map(item => {
      if (item["price"]) item["price"] = item["price"].replace(".", ",")
      return item
    })

    const columns = [
      {
        name: "manufacturers_cyr",
        label: "Виробник",
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "category_cyr",
        label: "Категорія",
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "price",
        label: "Ціна",
        options: {
          filter: true,
          sort: true,
        }
      },
      {
        name: "name",
        label: "Назва товару",
        options: {
          filter: true,
          filterType: "checkbox",
          sort: true,
        }
      },
    ]

    const options = {
      // filterType: "checkbox",
      draggable: true,
      showColumnsDraggable: true,
      fixedHeader: true,
      tableBodyHeight: "auto",
      searchable: true,
      filter: true,
      filterType: "dropdown",
      rowsPerPage: "50",
      responsive: false,
      selectableRows: "none",
      jumpToPage: true,
      // confirmFilters: true,
      // resizableColumns: true,
      downloadOptions: {
        filename: "MihodiRivne.csv"
      },
      rowsPerPageOptions: [50, 100, 150, (Data ? Data.length : null)],
    }

    const ProductsTable = <MUIDataTable
      title={"Товари на выгрузку Excel"}
      theme={this.getMuiTheme()}
      data={(Data ? Data : [])}
      columns={columns}
      options={options}
    />

    return (
      <Fragment>
        <Header/>
        <div className="container-fluid">
          <div className="control-panel-edit-product">
            <MenuTabs/>
            <div className="col">
              <div className="informer d-none text-center"/>
              {ProductsTable}
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = ({ Products, isAdmin }) => {
  return {
    isAdmin: isAdmin,
    ProductsRequest: Products.request,
    ProductsData: Products.data,
    ProductsError: Products.error,
  }
}

export default connect(mapStateToProps, null)(ControlProductPrice)
