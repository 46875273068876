import { takeLatest } from "redux-saga/effects"
import ACTION from "../actions/actionTypes"
import { categorySaga } from "./categorySaga"
import { manufacturersSaga } from "./manufacturersSaga"
import { productsSaga } from "./productsSaga"
import { latestProductsSaga } from "./latestProductsSaga"
import { topProductsSaga } from "./topProductsSaga"
import { stockProductsSaga } from "./stockProductsSaga"
// import {filterSaga} from './filterSaga'
import { productSaga } from "./productSaga"

// eslint-disable-next-line
export default function * () {
  yield takeLatest(ACTION.GET_CATEGORIES_REQUEST, categorySaga)
  yield takeLatest(ACTION.GET_MANUFACTURERS_REQUEST, manufacturersSaga)
  yield takeLatest(ACTION.GET_PRODUCTS_REQUEST, productsSaga)
  yield takeLatest(ACTION.GET_LATEST_PRODUCTS_REQUEST, latestProductsSaga)
  yield takeLatest(ACTION.GET_TOP_PRODUCTS_REQUEST, topProductsSaga)
  yield takeLatest(ACTION.GET_STOCK_PRODUCTS_REQUEST, stockProductsSaga)
  // yield takeLatest(ACTION.PRODUCTS_FILTER, filterSaga)
  yield takeLatest(ACTION.GET_PRODUCT_REQUEST, productSaga)
};
