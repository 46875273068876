// eslint-disable-next-line
export default {
  // CATEGORIES TYPES
  GET_CATEGORIES_REQUEST: "GET_CATEGORIES_REQUEST",
  GET_CATEGORIES_RESPONSE: "GET_CATEGORIES_RESPONSE",
  GET_CATEGORIES_ERROR: "GET_CATEGORIES_ERROR",
  ACTIVE_CATEGORY: "ACTIVE_CATEGORY",

  // MANUFACTURERS TYPES
  GET_MANUFACTURERS_REQUEST: "GET_MANUFACTURERS_REQUEST",
  GET_MANUFACTURERS_RESPONSE: "GET_MANUFACTURERS_RESPONSE",
  GET_MANUFACTURERS_ERROR: "GET_MANUFACTURERS_ERROR",
  ACTIVE_MANUFACTURERS: "ACTIVE_MANUFACTURERS",

  // PRODUCTS TYPES
  GET_PRODUCTS_REQUEST: "GET_PRODUCTS_REQUEST",
  GET_PRODUCTS_RESPONSE: "GET_PRODUCTS_RESPONSE",
  GET_PRODUCTS_ERROR: "GET_PRODUCTS_ERROR",
  GET_LATEST_PRODUCTS_REQUEST: "GET_LATEST_PRODUCTS_REQUEST",
  GET_LATEST_PRODUCTS_RESPONSE: "GET_LATEST_PRODUCTS_RESPONSE",
  GET_LATEST_PRODUCTS_ERROR: "GET_LATEST_PRODUCTS_ERROR",
  GET_TOP_PRODUCTS_REQUEST: "GET_TOP_PRODUCTS_REQUEST",
  GET_TOP_PRODUCTS_RESPONSE: "GET_TOP_PRODUCTS_RESPONSE",
  GET_TOP_PRODUCTS_ERROR: "GET_TOP_PRODUCTS_ERROR",
  GET_STOCK_PRODUCTS_REQUEST: "GET_STOCK_PRODUCTS_REQUEST",
  GET_STOCK_PRODUCTS_RESPONSE: "GET_STOCK_PRODUCTS_RESPONSE",
  GET_STOCK_PRODUCTS_ERROR: "GET_STOCK_PRODUCTS_ERROR",
  PRODUCTS_FILTER: "PRODUCTS_FILTER",
  FILTERS: "FILTERS",
  LAST_PRODUCT: "LAST_PRODUCT",

  // SINGLE PRODUCT TYPES
  GET_PRODUCT_REQUEST: "GET_PRODUCT_REQUEST",
  GET_PRODUCT_RESPONSE: "GET_PRODUCT_RESPONSE",
  GET_PRODUCT_ERROR: "GET_PRODUCT_ERROR",
  PRODUCT_FILTER: "PRODUCT_FILTER",
  PRODUCT_EDIT: "PRODUCT_EDIT",

  // LOGIN TYPES
  IS_ADMIN: "IS_ADMIN",
}
