// Import React Library
import React, { Fragment } from 'react'
import Navigation from '../../components/navigation'
import Footer from '../../components/footer'
import { NavLink } from 'react-router-dom'

export default class IsAdmin extends React.Component {
  render () {
    return (
      <Fragment>
        <Navigation/>
        <div className="container">
          <div className="no-authorize">
            <div>Ошибка, авторизируйтесь</div>
            <br/>
            <NavLink to="/control-panel" exact={true} activeClassName="active">
              <button className='btn btn-main'>Авторизация менеджера</button>
            </NavLink>
          </div>
        </div>
        <Footer/>
      </Fragment>
    )
  };
}