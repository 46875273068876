const initialState = {
  request: false,
  data: [],
  error: false,
}
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case 'GET_MANUFACTURERS_REQUEST':
      return {
        ...state,
        request: true,
        error: false,
      }
    case 'GET_MANUFACTURERS_RESPONSE':
      return {
        ...state,
        request: false,
        data: action.payload.data,
        error: false,
      }
    case 'GET_MANUFACTURERS_ERROR':
      return {
        ...state,
        request: true,
        error: action.payload,
      }
    default:
      return state
  }
};
export const manufacturersRequest = state => state.request
export const manufacturersData = state => state.data
export const manufacturersError = state => state.error
