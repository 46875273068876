// Import React Library
import React, { Fragment } from "react"
import { NavLink } from "react-router-dom"
import { connect } from "react-redux"
import Loader from "../../components/loader"
import { getSimilarProductsAPI } from "../../api"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

class OtherPositions extends React.Component {

  state = {
    products: [],
  }

  componentDidMount () {
    getSimilarProductsAPI({ category: this.props.category }).then((result) => {
      this.setState({ products: result })
    })
  }

  render () {

    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            infinite: false,
            dots: false,
          },
        },
        {
          breakpoint: 780,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 580,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    }

    const { products } = this.state
    if (!products) return <Loader/>

    const ItemProducts = products.map((item) => {
      return <div className={"col-12"} key={item._id}>
        <NavLink to={`/product/${item.category}/${item.manufacturers}/${item.translit}/`}>
          <div className={item.status ? "card text-center" : "card text-center grayscale"}>
            {(item.stock ? <div className='stock'>Акція</div> : "")}
            <picture>
              <source srcSet={`/uploads/${item.category}/${item.translit}/${item.translit}_1.webp`} type="image/webp"/>
              <source srcSet={`/uploads/${item.category}/${item.translit}/${item.translit}_1.png`} type="image/png"/>
              <img className="card-img-top" itemProp="image" src={`/uploads/${item.category}/${item.translit}/${item.translit}_1.webp`}
                   alt={item.translit}/>
            </picture>
            <div className="card-body">
              <div className="card-title text-center"><span itemProp="manufacturer">{item.manufacturers_cyr}</span>
                <br/>
                "<span itemProp="name">{item.name}</span>"
              </div>
            </div>
            <div className="card-footer">
              <div className="text-center">
                {item.status
                  ? <div>{item.price ? <div><strong itemProp="price" content={item.price}>{item.price}</strong> грн</div> :
                    <div>Уточнюйте по телефону</div>}</div>
                  : "Немає в наявності"}
              </div>
            </div>
          </div>
        </NavLink>
      </div>
    })

    return <div className="container-fluid otherProducts" itemScope itemType="http://schema.org/SomeProducts" itemID="#product">
      {(ItemProducts.length ?
        <Fragment>
          <div className="title text-center">Схожі товари</div>
          <Slider {...settings}>
            {ItemProducts}
          </Slider>
        </Fragment>
        : "")}
    </div>
  }
}

const mapStateToProps = ({ Products }) => {
  return {
    ProductsRequest: Products.request,
    ProductsData: Products.data,
    ProductsError: Products.error,
  }
}

export default connect(mapStateToProps, null)(OtherPositions)
