const initialState = ''
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case 'PRODUCT_EDIT':
      return action.payload
    default:
      return state
  }
};
export const productEdit = state => state
