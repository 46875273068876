import ACTION from "./actionTypes"

/***
 * CATEGORIES ACTIONS
 * @returns {{type: string}}
 * @constructor
 ***/

export const GET_CATEGORIES_REQUEST = () => {
  return {
    type: ACTION.GET_CATEGORIES_REQUEST,
  }
}

export const GET_CATEGORIES_RESPONSE = (payload) => {
  return {
    type: ACTION.GET_CATEGORIES_RESPONSE,
    payload,
  }
}

export const GET_CATEGORIES_ERROR = (payload) => {
  return {
    type: ACTION.GET_CATEGORIES_ERROR,
    payload,
  }
}

export const ACTIVE_CATEGORY = (payload) => {
  return {
    type: ACTION.ACTIVE_CATEGORY,
    payload,
  }
}

/***
 * MANUFACTURERS ACTIONS
 * @returns {{type: string}}
 * @constructor
 ***/

export const GET_MANUFACTURERS_REQUEST = () => {
  return {
    type: ACTION.GET_MANUFACTURERS_REQUEST,
  }
}

export const GET_MANUFACTURERS_RESPONSE = (payload) => {
  return {
    type: ACTION.GET_MANUFACTURERS_RESPONSE,
    payload,
  }
}

export const GET_MANUFACTURERS_ERROR = (payload) => {
  return {
    type: ACTION.GET_MANUFACTURERS_ERROR,
    payload,
  }
}

export const ACTIVE_MANUFACTURERS = (payload) => {
  return {
    type: ACTION.ACTIVE_MANUFACTURERS,
    payload,
  }
}

/***
 * PRODUCTS ACTIONS
 * @returns {{type: string}}
 * @constructor
 ***/

export const GET_PRODUCTS_REQUEST = () => {
  return {
    type: ACTION.GET_PRODUCTS_REQUEST,
  }
}

export const GET_PRODUCTS_RESPONSE = (payload) => {
  return {
    type: ACTION.GET_PRODUCTS_RESPONSE,
    payload,
  }
}

export const GET_PRODUCTS_ERROR = (payload) => {
  return {
    type: ACTION.GET_PRODUCTS_ERROR,
    payload,
  }
}

export const GET_LATEST_PRODUCTS_REQUEST = () => {
  return {
    type: ACTION.GET_LATEST_PRODUCTS_REQUEST,
  }
}

export const GET_LATEST_PRODUCTS_RESPONSE = (payload) => {
  return {
    type: ACTION.GET_LATEST_PRODUCTS_RESPONSE,
    payload,
  }
}

export const GET_LATEST_PRODUCTS_ERROR = (payload) => {
  return {
    type: ACTION.GET_LATEST_PRODUCTS_ERROR,
    payload,
  }
}

export const GET_TOP_PRODUCTS_REQUEST = () => {
  return {
    type: ACTION.GET_TOP_PRODUCTS_REQUEST,
  }
}

export const GET_TOP_PRODUCTS_RESPONSE = (payload) => {
  return {
    type: ACTION.GET_TOP_PRODUCTS_RESPONSE,
    payload,
  }
}

export const GET_TOP_PRODUCTS_ERROR = (payload) => {
  return {
    type: ACTION.GET_TOP_PRODUCTS_ERROR,
    payload,
  }
}

export const GET_STOCK_PRODUCTS_REQUEST = () => {
  return {
    type: ACTION.GET_STOCK_PRODUCTS_REQUEST,
  }
}

export const GET_STOCK_PRODUCTS_RESPONSE = (payload) => {
  return {
    type: ACTION.GET_STOCK_PRODUCTS_RESPONSE,
    payload,
  }
}

export const GET_STOCK_PRODUCTS_ERROR = (payload) => {
  return {
    type: ACTION.GET_STOCK_PRODUCTS_ERROR,
    payload,
  }
}

export const PRODUCTS_FILTER = (payload) => {
  return {
    type: ACTION.PRODUCTS_FILTER,
    payload,
  }
}

export const FILTERS = (payload) => {
  return {
    type: ACTION.FILTERS,
    payload,
  }
}

export const LAST_PRODUCT = (payload) => {
  return {
    type: ACTION.LAST_PRODUCT,
    payload,
  }
}

/***
 * SINGLE PRODUCT ACTIONS
 * @returns {{type: string}}
 * @constructor
 ***/

export const GET_PRODUCT_REQUEST = () => {
  return {
    type: ACTION.GET_PRODUCT_REQUEST,
  }
}

export const GET_PRODUCT_RESPONSE = (payload) => {
  return {
    type: ACTION.GET_PRODUCT_RESPONSE,
    payload,
  }
}

export const GET_PRODUCT_ERROR = (payload) => {
  return {
    type: ACTION.GET_PRODUCT_ERROR,
    payload,
  }
}

export const PRODUCT_FILTER = (payload) => {
  return {
    type: ACTION.PRODUCT_FILTER,
    payload,
  }
}

export const PRODUCT_EDIT = (payload) => {
  return {
    type: ACTION.PRODUCT_EDIT,
    payload,
  }
}

/***
 * LOGIN ACTIONS
 * @returns {{type: string}}
 * @constructor
 ***/

export const IS_ADMIN = (payload) => {
  return {
    type: ACTION.IS_ADMIN,
    payload,
  }
}