import { call, put } from 'redux-saga/effects'
import { GET_MANUFACTURERS_RESPONSE, GET_MANUFACTURERS_ERROR } from '../actions/actionCreator'
import { getManufacturersAPI } from '../api'
// eslint-disable-next-line
export function * manufacturersSaga () {
  try {
    yield put(GET_MANUFACTURERS_RESPONSE({ request: true }))
    const data = yield call(getManufacturersAPI)
    yield put(GET_MANUFACTURERS_RESPONSE({ request: false, data: data, error: false }))
  } catch (error) {
    yield put(GET_MANUFACTURERS_ERROR({ request: true, error: error }))
  }
}
