import { call, put } from 'redux-saga/effects'
import { GET_PRODUCTS_RESPONSE, GET_PRODUCTS_ERROR } from '../actions/actionCreator'
import { getProductsAPI } from '../api'
// eslint-disable-next-line
export function * productsSaga () {
  try {
    yield put(GET_PRODUCTS_RESPONSE({ request: true }))
    const data = yield call(getProductsAPI)
    yield put(GET_PRODUCTS_RESPONSE({ request: false, data: data, error: false }))
  } catch (error) {
    yield put(GET_PRODUCTS_ERROR({ request: true, error: error }))
  }
}
