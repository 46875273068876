import { call, put } from 'redux-saga/effects'
import { GET_LATEST_PRODUCTS_RESPONSE, GET_LATEST_PRODUCTS_ERROR } from '../actions/actionCreator'
import { getLatestProductsAPI } from '../api'
// eslint-disable-next-line
export function * latestProductsSaga () {
  try {
    yield put(GET_LATEST_PRODUCTS_RESPONSE({ request: true }))
    const data = yield call(getLatestProductsAPI)
    yield put(GET_LATEST_PRODUCTS_RESPONSE({ request: false, data: data, error: false }))
  } catch (error) {
    yield put(GET_LATEST_PRODUCTS_ERROR({ request: true, error: error }))
  }
}
