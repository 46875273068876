// Import React Library
import React, {Component, Suspense} from "react"
import {connect} from "react-redux"
import {PRODUCTS_FILTER} from "../actions/actionCreator"
import {getProductsBySearchAPI} from "../api"
import {NavLink} from "react-router-dom"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import {SmallLoader} from "./preloader"

class Search extends Component {

    state = {
        search: "",
        results: [],
    }

    // componentDidMount() {
    //     getProductsBySearchAPI({request: this.state.search}).then((result) => {
    //         this.setState({results: result})
    //     })
    // }

    searchProducts = (e) => {
        let val = e.target.value
        this.setState({search: val})
        this.props.dispatch(PRODUCTS_FILTER(val))
        if (this.state.search) getProductsBySearchAPI({request: this.state.search}).then((result) => {
            this.setState({results: result})
        })
    }

    render() {

        const settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 3,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: false,
                        dots: false,
                    },
                },
                {
                    breakpoint: 780,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2,
                    },
                },
                {
                    breakpoint: 580,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ],
        }

        const {ProductsFilter} = this.props
        const {results} = this.state
        let Item
        if (results.length) Item = results.map((item) => {
            return <div className={"col-12"} key={item._id}>
                <div itemScope itemType="http://schema.org/ItemList">
                    <div itemProp="itemListElement" itemScope itemType="http://schema.org/Product">
                        <NavLink itemProp="url" onClick={() => {
                            this.setState({search: null})
                            this.props.dispatch(PRODUCTS_FILTER(null))
                        }} to={`/product/${item.category}/${item.manufacturers}/${item.translit}/`}>
                            <div className={item.status ? "card text-center" : "card text-center grayscale"}>
                                {(item.stock ? <div className='stock'>Акція</div> : "")}
                                {(item.latest ? <div className='latest'>Новинка</div> : "")}
                                {(item.top ? <div className='top'>Топ продажів</div> : "")}
                                <picture>
                                    <source
                                        srcSet={`/uploads/${item.category}/${item.translit}/${item.translit}_1.webp`}
                                        type="image/webp"/>
                                    <source srcSet={`/uploads/${item.category}/${item.translit}/${item.translit}_1.png`}
                                            type="image/png"/>
                                    <img src={`/uploads/${item.category}/${item.translit}/${item.translit}_1.webp`}
                                         alt={item.translit} className="card-img-top"
                                         itemProp="image"/>
                                </picture>
                                <div className="card-body">
                                    <div className="card-title text-center"><span
                                        itemProp="manufacturer">{item.manufacturers_cyr}</span>
                                        <br/>
                                        "<span itemProp="name">{item.name}"</span></div>
                                </div>
                                <div className="card-footer">
                                    <div className="text-center">
                                        <div itemProp="offers" itemScope itemType="http://schema.org/Offer">
                                            {item.status
                                                ? <div>{item.price ? <div><strong itemProp="price"
                                                                                  content={item.price}>{item.price}</strong> грн
                                                    </div> :
                                                    <div>Уточнюйте по телефону</div>}</div>
                                                : "Немає в наявності"}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </NavLink>
                    </div>
                </div>
            </div>
        })

        return <div className="container-fluid">
            <div className="search_bar">
                <div className="row">
                    <div className="col-12 col-lg-5 col-xl-3 d-none d-lg-block d-xl-block text-center title">
                        Продукція
                    </div>
                    <div className="col-12  col-lg-7 col-xl-9 form">
                        <Suspense fallback={<SmallLoader/>}>
                            <div className="search">
                                <input type="text"
                                       className={(ProductsFilter ? "search form-control" : "search form-control")}
                                       value={(ProductsFilter ? ProductsFilter : "")}
                                       placeholder={"Пошук... (Наприклад: варені ковбаси)"}
                                       onChange={this.searchProducts}/>
                                {(ProductsFilter ?
                                    <div className="results">
                                        {(results.length ?
                                            <Slider {...settings}>
                                                {Item}
                                            </Slider> : <div className="no-product"/>)}
                                    </div> : null)}
                            </div>
                        </Suspense>
                    </div>
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = ({ProductsFilter}) => {
    return {
        ProductsFilter: ProductsFilter,
    }
}

export default connect(mapStateToProps, null)(Search)
