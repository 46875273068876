const initialState = false
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case 'IS_ADMIN':
      return action.payload
    default:
      return state
  }
};
export const isAdmin = state => state
