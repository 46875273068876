const initialState = {
  category: false,
  categoryName: '',
  manufacturers: false,
  manufacturersName: ''
}
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case 'FILTERS':
      return action.payload
    default:
      return state
  }
};
export const Filters = state => state
