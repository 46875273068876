// Import React Library
import React, { Fragment } from "react"
import { NavLink } from "react-router-dom"
import { logOutAPI } from "../../api"
import ApiRequest from "../../helpers/ApiRequest"

export default class MenuTabs extends React.Component {
  render () {
    return (
      <Fragment>
        <div className="container">
          <div className="row">
            <div className="col-12 list-inline justify-content-center control-navigation">
              <nav>
                <ul className="nav justify-content-center">
                  <NavLink to="/control-panel/categories" exact={true} activeClassName="active">
                    <li className="nav-item middle">Додати категорію</li>
                  </NavLink>
                  <NavLink to="/control-panel/manufacturers" exact={true} activeClassName="active">
                    <li className="nav-item middle">Додати виробника</li>
                  </NavLink>
                  <NavLink to="/control-panel/add-products" exact={true} activeClassName="active">
                    <li className="nav-item middle">Додати товар</li>
                  </NavLink>
                  <NavLink to="/control-panel/edit-products" exact={true} activeClassName="active">
                    <li className="nav-item middle">Редагувати товари</li>
                  </NavLink>
                  <li id={"generate"} className="nav-item middle" onClick={() => {
                    ApiRequest.GET("https://mihodi.rivne.ua/api/sitemap/generate").then((data) => {
                      let btn = document.querySelector("#generate")
                      if (data.status) {
                        btn.style = "background-color: green"
                        btn.innerText = "Створено і відправлений"
                      }
                    })
                  }} style={{ cursor: "pointer" }}>Створити Sitemap
                  </li>
                  <NavLink to="/" exact={true} activeClassName="active" onClick={() => {
                    logOutAPI()
                  }}>
                    <li className="nav-item middle">Вийти</li>
                  </NavLink>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </Fragment>
    )
  };
}
