// Import React Library
import React, {Component} from "react"
import {NavLink} from "react-router-dom"

export default class Navigation extends Component {
    render() {
        const categories = [
            {
                link: "",
                name: "Головна",
            },
            {
                link: "contacts",
                name: "Контакти",
            },
            {
                link: "about",
                name: "Про нас",
            },
            {
                link: "delivery",
                name: "Доставка",
            }]
        const Item = categories.map((item) => {
            return (
                <NavLink key={item.link} to={"/" + item.link} onClick={() => {
                }} exact={true} activeClassName="active"
                         className={item.name === "Головна" ? "d-none d-xl-block" : null}>
                    <li key={item.link} className="nav-item">{item.name}</li>
                </NavLink>
            )
        })
        return <div className="menu">
            <div className="d-flex justify-content-center">
                <nav className="navbar">
                    <ul className="nav">{Item}</ul>
                </nav>
            </div>
        </div>
    }
}
