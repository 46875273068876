// Import React Library
import React, {Fragment} from "react"
import {NavLink} from "react-router-dom"
import Header from "../../components/header"
import Footer from "../../components/footer"
import Loader from "../../components/loader"
import Modal from "react-modal"
import OtherPositions from "./otherPositions"
import {connect} from "react-redux"
import {GET_PRODUCT_REQUEST, PRODUCT_FILTER} from "../../actions/actionCreator"

import "react-responsive-carousel/lib/styles/carousel.min.css"
import {Carousel} from "react-responsive-carousel"

class ProductPage extends React.Component {

    state = {
        loader: true,
        products: null,
        modalIsOpen: false,
        isMountSeo: false,
    }

    renderHTML = (row) => React.createElement("div", {dangerouslySetInnerHTML: {__html: row}})

    customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            width: "400px",
            transform: "translate(-50%, -50%)",
            color: "#ffffff",
            padding: "25px",
            backgroundColor: "#414A4C",
            opacity: "1",
            border: "3px dashed #666666",
        },
    }

    Loader = () => {
        setTimeout(() => {
            this.setState({loader: false})
        }, 1000)
    }

    componentDidMount() {
        this.props.dispatch(PRODUCT_FILTER({
            category: this.props.match.params.category,
            manufacturers: this.props.match.params.manufacturers,
            translit: this.props.match.params.name,
        }))
        this.props.dispatch(GET_PRODUCT_REQUEST())
        this.Loader()
        this.setState({isMountSeo: false})

        window.scrollTo(0, 0)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match.url !== prevProps.match.url) {
            this.props.dispatch(PRODUCT_FILTER({
                category: this.props.match.params.category,
                manufacturers: this.props.match.params.manufacturers,
                translit: this.props.match.params.name,
            }))
            this.props.dispatch(GET_PRODUCT_REQUEST())
            this.Loader()
            this.setState({isMountSeo: false})
            if (this.props.location !== prevProps.location) {
                window.scrollTo(0, 0)
            }
        }
    }

    openModal = () => this.setState({modalIsOpen: true})

    closeModal = () => this.setState({modalIsOpen: false})

    render() {
        const {ProductData} = this.props

        if (this.state.loader || !ProductData) return <Fragment><Header/><Loader/></Fragment>

        const product = ProductData.product
        const images = ProductData.images

        const ItemImage = images.map((item) => {
            return <picture key={item.name}>
                <source srcSet={item.src + ".webp"} type="image/webp"/>
                <source srcSet={item.src + ".png"} type="image/png"/>
                <img itemProp="image" src={item.src + ".webp"} alt={item.name}/>
            </picture>
        })

        const seoDescription = `У нас ви можете купити ${product.name} ${(product.code
            ? `(${product.code})`
            : "")} виробника "${product.manufacturers_cyr}" за найвигіднішою ціною з доставкою у Луцьк, Київ, Хмельницький, Житомир, Рівне, Тернопіль, Львів, Ужгород, Чернівці, Івано-Франківськ, Вінниця та інші міста України. Всім оптовим покупцям надаються знижки на товар.`
        const seoKeywords = `${product.name}`
        if (product && !this.state.isMountSeo) {
            document.title = (product.seo_title ? product.seo_title
                : `${product.category_cyr}, купити ${product.name} від виробника ${product.manufacturers_cyr}, Україна`)
            document.getElementsByTagName("META")[3].content = (product.seo_description ? product.seo_description : seoDescription)
            document.getElementsByTagName("META")[4].content = (product.seo_keywords ? product.seo_keywords : seoKeywords)
        }
        const og_img_url = `${process.env.REACT_APP_URL}/uploads/${product.category}/${product.translit}/${product.translit}_1.webp`
        return <Fragment>
            <Header/>
            <div className="container-fluid" style={{padding: "0px"}}>
                <div className="product">
                    <meta property="og:title" content={product.name}/>
                    <meta property="og:image" content={og_img_url}/>
                    <meta property="og:type" content="article"/>
                    <meta property="og:url" content={process.env.REACT_APP_URL}/>
                    <meta property="og:description" content={product.description}/>
                    <div className="block">
                        <div className="row">
                            <nav aria-label="breadcrumb">
                                <div className="justify-content-center">
                                    <ol className="breadcrumb" itemScope itemType="http://schema.org/BreadcrumbList">
                    <span itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                    <li className="breadcrumb-item" itemProp="item">
                      <a itemProp="item" href={`/category/${product.category}`}>
                        <span itemProp="name">{product.category_cyr}</span></a>
                      <meta itemProp="position" content="1"/>
                    </li>
                      </span>
                                        &#8194;&ndash;&#8194;
                                        <span itemProp="itemListElement" itemScope
                                              itemType="http://schema.org/ListItem">
                    <li className="breadcrumb-item" itemProp="item">
                      <a itemProp="item" href={`/manufacturer/${product.manufacturers}`}>
                        <span itemProp="name">{product.manufacturers_cyr}</span></a>
                      <meta itemProp="position" content="2"/>
                    </li>
                      </span>
                                        &#8194;&ndash;&#8194;
                                        <span itemProp="itemListElement" itemScope
                                              itemType="http://schema.org/ListItem">
                    <li className="breadcrumb-item active" itemProp="item" aria-current="page">
                      <a itemProp="item" href="/">
                        <span itemProp="name">{product.name}</span></a>
                      <meta itemProp="position" content="3"/>
                    </li>
                      </span>
                                    </ol>
                                </div>
                            </nav>
                        </div>
                        <div className="row info" itemScope itemType="http://schema.org/Product">
                            <div className="col-12 col-md-5 image text-center">
                                <div className={product.status ? "" : "grayscale"}>
                                    <Carousel infiniteLoop={true} showThumbs={false} swipeable={true}
                                              autoPlay={true}>{ItemImage}</Carousel>
                                </div>
                            </div>
                            <div className="col-12 col-md-7 about">
                                <h1 itemProp="name">{product.name}</h1>
                                <div>Виробник: <span itemProp="manufacturer">{product.manufacturers_cyr}</span>
                                </div>
                                {(product.code ? <div>Код товару: {product.code}
                                    <meta itemProp="productID" content={product.code}/>
                                </div> : "")}
                                <div>
                                    <NavLink to={"/delivery"} exact={true}>Інформація про доставку оптовим
                                        покупцям</NavLink>
                                </div>
                                <div itemProp="offers" itemScope itemType="http://schema.org/Offer">
                                    <div style={{marginTop: "25px"}}>
                                        {(product.status ? <span style={{color: "#00E54E", fontSize: "18px"}}>
                            <link itemProp="availability" href="http://schema.org/InStock"/>В наявності на складі
                        </span> : <del>Замовлення по телефону</del>)}
                                    </div>
                                    <div>
                                        {(product.price ?
                                            <div className={"text-left"} style={{marginTop: "10px", fontSize: "52px"}}>
                                                {(product.status ?
                                                    <h4 style={{marginTop: "35px", fontSize: "52px"}}><span
                                                        itemProp="price" content={product.price}>{product.price}</span>
                                                        <span itemProp="priceCurrency" content="UAH"> ₴</span>
                                                    </h4> : "")}</div> : null)}
                                    </div>
                                    <div className="buy">
                                        <h2>Для оптових покупців: </h2>
                                        Якщо ви хочете стати нашим партнером, зателефонуйте на номер, вказаний в розділі
                                        'Контакти', або задайте ваше запитання у соціальних мережах.
                                    </div>

                                    {/*<div>*/}
                                    {/*  {(product.status ? <button type="button" className="btn btn-lg btn-main btn-block"*/}
                                    {/*                             style={{ marginTop: "40px" }} onClick={this.openModal}>Купить</button> :*/}
                                    {/*    <button type="button" className="btn btn-lg btn-main btn-block"*/}
                                    {/*            style={{ marginTop: "50px" }} onClick={this.openModal} disabled={true}>Уточняйте наличие по телефону</button>)}*/}
                                    {/*</div>*/}


                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <h2 className='description' itemProp="description">
                                        {(product.description ? this.renderHTML(product.description) : this.renderHTML(seoDescription))}
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <OtherPositions name={product.name} category={product.category}/>
                    <Modal isOpen={this.state.modalIsOpen} onRequestClose={this.closeModal}
                           className="Modal"
                           overlayClassName="Overlay" style={this.customStyles}>
                        <h4 ref={subtitle => this.subtitle = subtitle}>
                            Для оптових покупців:
                        </h4>
                        {/*<button onClick={this.closeModal}>close</button>*/}
                        <div style={{textSize: "16px"}}> Якщо ви хочете стати нашим партнером, зателефонуйте на номер,
                            вказаний в розділі 'Контакти', або задайте ваше запитання у соціальних мережах.
                        </div>
                    </Modal>
                </div>
            </div>
            <Footer/>
        </Fragment>
    }
}

const mapStateToProps = ({Product}) => {
    return {
        ProductRequest: Product.request,
        ProductData: Product.data,
        ProductError: Product.error,
    }
}

export default connect(mapStateToProps, null)(ProductPage)
