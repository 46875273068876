import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Loader from '../../../components/loader'
import { GET_CATEGORIES_REQUEST, GET_MANUFACTURERS_REQUEST, GET_PRODUCTS_REQUEST, PRODUCT_EDIT } from '../../../actions/actionCreator'
import { deleteProductsAPI, editProductsAPI } from '../../../api'
import { Collapse } from 'react-collapse'

class EditProductForm extends Component {

  initialState = {
    id: this.props.ProductEdit.product[0]._id,
    loader: true,
    name: this.props.ProductEdit.product[0].name,
    description: this.props.ProductEdit.product[0].description,
    status: this.props.ProductEdit.product[0].status,
    stock: this.props.ProductEdit.product[0].stock,
    top: this.props.ProductEdit.product[0].top,
    latest: this.props.ProductEdit.product[0].latest,
    price: this.props.ProductEdit.product[0].price,
    code: this.props.ProductEdit.product[0].code,
    category: this.props.ProductEdit.product[0].category_cyr,
    manufacturers: this.props.ProductEdit.product[0].manufacturers_cyr,
    file1: null,
    file2: null,
    file3: null,
    isOpened: false,
    seoTitle: this.props.ProductEdit.product[0].seo_title,
    seoDescription: this.props.ProductEdit.product[0].seo_description,
    seoKeywords: this.props.ProductEdit.product[0].seo_keywords,
  }

  state = this.initialState

  componentDidMount () {
    this.props.dispatch(GET_CATEGORIES_REQUEST())
    this.props.dispatch(GET_MANUFACTURERS_REQUEST())
    this.Loader()
    this.setState(this.initialState)
  }

  Loader = () => setTimeout(() => {this.setState({ loader: false })}, 2000)

  ProductDelete = (id) => {
    deleteProductsAPI({ id }).then((result) => {
      this.props.dispatch(PRODUCT_EDIT({ product: '', form: false }))
      this.props.dispatch(GET_PRODUCTS_REQUEST())
    })
  }

  NameChange = (e) => this.setState({ name: e.target.value })
  DescriptionChange = (e) => this.setState({ description: e.target.value })
  StatusChange = (e) => this.setState({ status: e.target.checked })
  StockChange = (e) => this.setState({ stock: e.target.checked })
  TopChange = (e) => this.setState({ top: e.target.checked })
  LatestChange = (e) => this.setState({ latest: e.target.checked })
  PriceChange = (e) => this.setState({ price: e.target.value })
  CodeChange = (e) => this.setState({ code: e.target.value })
  CategoryChange = (e) => this.setState({ category: e.target.value })
  ManufacturersChange = (e) => this.setState({ manufacturers: e.target.value })

  File1Change = (e) => {
    let reader = new FileReader()
    reader.readAsDataURL(e.target.files[0])
    reader.onload = (e) => this.setState({ file1: e.target.result })
  }

  File2Change = (e) => {
    let reader = new FileReader()
    reader.readAsDataURL(e.target.files[0])
    reader.onload = (e) => this.setState({ file2: e.target.result })
  }

  File3Change = (e) => {
    let reader = new FileReader()
    reader.readAsDataURL(e.target.files[0])
    reader.onload = (e) => this.setState({ file3: e.target.result })
  }

  seoTitleChange = (e) => this.setState({ seoTitle: e.target.value })
  seoDescriptionChange = (e) => this.setState({ seoDescription: e.target.value })
  seoKeywordsChange = (e) => this.setState({ seoKeywords: e.target.value })

  handleSubmit = (e) => {
    e.preventDefault()
    let informerClass = document.querySelector('.informer')

    if (this.state.name && this.state.category && this.state.manufacturers) {
      const formData = {
        id: this.state.id,
        name: this.state.name,
        description: this.state.description,
        status: this.state.status,
        stock: this.state.stock,
        top: this.state.top,
        latest: this.state.latest,
        price: this.state.price,
        code: this.state.code,
        category: this.state.category,
        manufacturers: this.state.manufacturers,
        file: [
          this.state.file1,
          this.state.file2,
          this.state.file3,
        ],
        seoTitle: this.state.seoTitle,
        seoDescription: this.state.seoDescription,
        seoKeywords: this.state.seoKeywords,
      }
      editProductsAPI(formData).then((result) => {
        if (result) {
          this.props.dispatch(PRODUCT_EDIT({ product: '', form: false }))
          this.props.dispatch(GET_PRODUCTS_REQUEST())
          informerClass.classList.add('success', 'animated', 'zoomIn')
          informerClass.classList.remove('d-none')
          informerClass.innerHTML = 'Товар успішно відредагований'
          this.setState(this.initialState)
          setTimeout(() => {
            informerClass.classList.add('d-none')
            informerClass.classList.remove('success', 'animated', 'zoomIn')
          }, 5000)
        }
      })
    }
  }

  render () {
    const { ProductEdit, CategoryData, ManufacturersData } = this.props

    const { isOpened } = this.state

    if (this.state.loader || !ProductEdit) return (<Fragment><Loader/></Fragment>)

    let categories = [], manufacturers = []
    if (CategoryData) categories = CategoryData
    if (ManufacturersData) manufacturers = ManufacturersData

    const ItemCategory = categories.map((item) => {
      return <option key={item._id} value={item.name}>{item.name}</option>
    })

    const ItemManufacturer = manufacturers.map((item) => {
      return <option key={item._id} value={item.name}>{item.name}</option>
    })

    const buttons = [
      { func: this.File1Change, name: 'file1', id: 'file-input-1' },
      { func: this.File2Change, name: 'file2', id: 'file-input-2' },
      { func: this.File3Change, name: 'file3', id: 'file-input-3' }]
    const ItemFileButton = buttons.map((item) => {
      return (
        <div className={'col-4'} id="upload-container" key={item.id}>
          <div>
            <input type='file' id={item.id} name={item.name} accept="image/*" onChange={item.func} className={'form-control file-control'}/>
          </div>
        </div>
      )
    })

    return (
      <Fragment>
        <div className='edit-product-form'>
          <form onSubmit={this.handleSubmit} encType="multipart/form-data" method="POST">
            <div className="form-group">
              <div className={'row'}>
                <div className={'col-5'}>
                  <input name="name" type="text" value={this.state.name} onChange={this.NameChange} placeholder="Назва"
                         className={'form-control'} required={true}/>
                </div>
                <div className={'col-2 text-center stock custom-control custom-switch'}>
                  <input type="checkbox" className="custom-control-input" id="stock" onChange={this.StockChange} defaultChecked={this.state.stock}/>
                  <label className="custom-control-label" htmlFor="stock">Акція</label>
                </div>
                <div className={'col-1 text-center stock custom-control custom-switch'}>
                  <input type="checkbox" className="custom-control-input" id="top" onChange={this.TopChange} defaultChecked={this.state.top}/>
                  <label className="custom-control-label" htmlFor="top">Топ</label>
                </div>
                <div className={'col-2 text-center stock custom-control custom-switch'}>
                  <input type="checkbox" className="custom-control-input" id="latest" onChange={this.LatestChange} defaultChecked={this.state.latest}/>
                  <label className="custom-control-label" htmlFor="latest">Новинка</label>
                </div>
                <div className={'col-2 text-center status custom-control custom-switch'}>
                  <input type="checkbox" className="custom-control-input" id="status" onChange={this.StatusChange}
                         defaultChecked={this.state.status}/>
                  <label className="custom-control-label" htmlFor="status">Наявність</label>
                </div>
              </div>
              <textarea name="description" value={this.state.description} onChange={this.DescriptionChange} placeholder="Опис"
                        className={'form-control'}/>
              <div className={'row'}>
                <div className={'col-6'}>
                  <input name="price" type="text" value={this.state.price} onChange={this.PriceChange} placeholder="Ціна"
                         className={'form-control'}/>
                </div>
                <div className={'col-6'}>
                  <input name="code" type="text" value={this.state.code} onChange={this.CodeChange} placeholder="Код товару"
                         className={'form-control'}/>
                </div>
              </div>
              <div className={'row'}>
                <div className={'col-6'}>
                  <select name="category" value={this.state.category} onChange={this.CategoryChange} className={'form-control select-control'}
                          required={true}>
                    {ItemCategory}
                  </select>
                </div>
                <div className={'col-6'}>
                  <select name="manufacturers" value={this.state.manufacturers} onChange={this.ManufacturersChange}
                          className={'form-control select-control'} required={true}>
                    {ItemManufacturer}
                  </select>
                </div>
              </div>
              <div className={'row'}>{ItemFileButton}</div>
            </div>
            <div className="row">
              <div className="col">
                <Collapse isOpened={isOpened}>
                  <div className={'text-center'}> SEO дані</div>
                  <div style={{ margin: '25px 0' }}>
                    <input name="seo_title" type="text" value={this.state.seoTitle} onChange={this.seoTitleChange}
                           placeholder="SEO title [до 100 символів]"
                           className={'form-control'}
                           maxLength="100"/>
                    <textarea name="seo_description" value={this.state.seoDescription} onChange={this.seoDescriptionChange}
                              placeholder="SEO description [до 500 символів]"
                              className={'form-control'} maxLength="500"/>
                    <input name="seo_keywords" type="text" value={this.state.seoKeywords} onChange={this.seoKeywordsChange}
                           placeholder="SEO keywords [до 100 символів]"
                           className={'form-control'}
                           maxLength="100"/>
                  </div>
                </Collapse>
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <button type="submit" className={'btn btn-success btn-lg btn-block'}>Зберегти</button>
              </div>
              <div className="col-3">
                <div className={'btn btn-primary btn-lg btn-block'}
                     onClick={() => (this.state.isOpened ? this.setState({ isOpened: false }) : this.setState({ isOpened: true }))}>SEO
                </div>
              </div>
              <div className="col-3">
                <div className={'btn btn-warning btn-lg btn-block'} onClick={() => {
                  this.props.dispatch(PRODUCT_EDIT({ product: '', form: false }))
                }}>Скасувати
                </div>
              </div>
              <div className="col-3">
                <div className={'btn btn-danger btn-lg btn-block'} onDoubleClick={() => this.ProductDelete(this.state.id)}>Удалить</div>
                <div className='row'>
                  <div className="col text-right">
                    <small>* "Видалити" - подвійний клік ЛКМ</small>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = ({ ProductEdit, Category, Manufacturers }) => {
  return {
    ProductEdit: ProductEdit,
    CategoryRequest: Category.request,
    CategoryData: Category.data,
    CategoryError: Category.error,
    ManufacturersRequest: Manufacturers.request,
    ManufacturersData: Manufacturers.data,
    ManufacturersError: Manufacturers.error,
  }
}

export default connect(mapStateToProps, null)(EditProductForm)
