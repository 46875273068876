import React, {Component, Fragment} from "react"
import {connect} from "react-redux"
import {GET_CATEGORIES_REQUEST, GET_MANUFACTURERS_REQUEST} from "../../../actions/actionCreator"
import {addProductsAPI, getProductsAPI} from "../../../api"
import {Collapse} from "react-collapse"

class AddProductForm extends Component {

  initialState = {
    mount: false,
    name: "",
    description: "",
    stock: false,
    top: false,
    latest: false,
    price: "",
    code: "",
    category: "",
    manufacturers: "",
    file1: null,
    file2: null,
    file3: null,
    isOpened: false,
    seoTitle: "",
    seoDescription: "",
    seoKeywords: "",
    products: [],
    filter: [],

  }
  state = this.initialState

  componentDidMount() {
    this.props.dispatch(GET_CATEGORIES_REQUEST())
    this.props.dispatch(GET_MANUFACTURERS_REQUEST())
  }

  handleFilter = (newFilter) => {
    if (newFilter !== "") this.setState({
      filter: this.state.products.filter(product => product.toLowerCase().includes(newFilter.toLowerCase()))
    })
    else this.setState({filter: []})
  }

  NameChange = (e) => {
    this.setState({name: e.target.value})
    this.handleFilter(e.target.value)
  }

  DescriptionChange = (e) => this.setState({description: e.target.value})

  StockChange = (e) => this.setState({stock: e.target.checked})
  TopChange = (e) => this.setState({top: e.target.checked})
  LatestChange = (e) => this.setState({latest: e.target.checked})

  PriceChange = (e) => this.setState({price: e.target.value})

  CodeChange = (e) => this.setState({code: e.target.value})

  CategoryChange = (e) => this.setState({category: e.target.value})

  ManufacturersChange = (e) => this.setState({manufacturers: e.target.value})

  File1Change = (e) => {
    let reader = new FileReader()
    reader.readAsDataURL(e.target.files[0])
    reader.onload = (e) => {
      this.setState({file1: e.target.result})
    }
  }

  File2Change = (e) => {
    let reader = new FileReader()
    reader.readAsDataURL(e.target.files[0])
    reader.onload = (e) => {
      this.setState({file2: e.target.result})
    }
  }

  File3Change = (e) => {
    let reader = new FileReader()
    reader.readAsDataURL(e.target.files[0])
    reader.onload = (e) => {
      this.setState({file3: e.target.result})
    }
  }

  seoTitleChange = (e) => this.setState({seoTitle: e.target.value})
  seoDescriptionChange = (e) => this.setState({seoDescription: e.target.value})
  seoKeywordsChange = (e) => this.setState({seoKeywords: e.target.value})

  handleSubmit = (e) => {
    e.preventDefault()
    if (this.state.name && this.state.category && this.state.manufacturers && this.state.file1) {
      const formData = {
        name: this.state.name,
        description: this.state.description,
        stock: this.state.stock,
        top: this.state.top,
        latest: this.state.latest,
        price: this.state.price,
        code: this.state.code,
        category: this.state.category,
        manufacturers: this.state.manufacturers,
        file: [
          this.state.file1,
          this.state.file2,
          this.state.file3,
        ],
        seoTitle: this.state.seoTitle,
        seoDescription: this.state.seoDescription,
        seoKeywords: this.state.seoKeywords,
      }
      addProductsAPI(formData).then((result) => {
        let informerClass = document.querySelector(".informer")
        if (result.response.success) {
          informerClass.classList.add("success", "animated", "zoomIn")
          informerClass.classList.remove("d-none")
          informerClass.innerHTML = result.response.success.msg
          setTimeout(() => {
            informerClass.classList.add("d-none")
            informerClass.classList.remove("success", "animated", "zoomIn")
          }, 5000)
          this.setState(this.initialState)
          document.querySelector(".btn-main").style.backgroundColor = ""
          document.querySelector(".btn-main").innerHTML = "Сохранить"
        }
        if (result.response.error) {
          informerClass.classList.add("error", "animated", "zoomIn")
          informerClass.classList.remove("d-none")
          informerClass.innerHTML = result.response.error.msg
          setTimeout(() => {
            informerClass.classList.add("d-none")
            informerClass.classList.remove("success", "animated", "zoomIn")
          }, 5000)
        }
      })
    } else console.log("Ошибка")
  }

  render() {
    const {CategoryData, ManufacturersData} = this.props

    const {isOpened} = this.state
    let categories = [], manufacturers = []
    if (CategoryData) categories = CategoryData
    if (ManufacturersData) manufacturers = ManufacturersData

    if (!this.state.mount) getProductsAPI().then(r => {
      let prods = []
      r.map(item => prods.push(item.name))
      this.setState({products: prods, mount: true})
    })


    const ItemCategory = categories.map((item) => {
      return (<option key={item._id}>{item.name}</option>)
    })

    const ItemManufacturer = manufacturers.map((item) => {
      return (<option key={item._id}>{item.name}</option>)
    })

    const buttons = [
      {func: this.File1Change, name: "file1", id: "file-input-1"},
      {func: this.File2Change, name: "file2", id: "file-input-2"},
      {func: this.File3Change, name: "file3", id: "file-input-3"}]
    const ItemFileButton = buttons.map((item) => {
      return (
          <div className={"col-4"} id="upload-container" key={item.id}>
            <div>
              <input type="file" id={item.id} name={item.name} accept="image/*" onChange={item.func}
                     className={"form-control file-control"}/>
            </div>
          </div>
      )
    })

    return (
        <Fragment>
          <form onSubmit={this.handleSubmit} id="addForm" encType="multipart/form-data" method="POST">
            <div className="form-group">
              <div className={"row"}>
                <div className={"col-7"}>
                  <input name="name" type="text" value={this.state.name}
                         onChange={this.NameChange}
                         placeholder="Название [до 100 символов]"
                         className={"form-control"}
                         required={true} maxLength="100"/>
                  {this.state.filter.length ? <div style={{
                    position: "absolute",
                    backgroundColor: "#353434",
                    zIndex: 100,
                    padding: "15px 0",
                    maxHeight: "400px",
                    width: "96%",
                    overflowY: "auto"
                  }}>
                    {this.state.filter.map((filter) => <p key={filter}>{filter}</p>)}
                  </div> : null}
                </div>
                <div className={"col-2 text-center stock custom-control custom-switch"}>
                  <input type="checkbox" className="custom-control-input" id="stock"
                         onChange={this.StockChange} defaultChecked={false}/>
                  <label className="custom-control-label" htmlFor="stock">Акция</label>
                </div>
                <div className={"col-1 text-center stock custom-control custom-switch"}>
                  <input type="checkbox" className="custom-control-input" id="top"
                         onChange={this.TopChange} defaultChecked={false}/>
                  <label className="custom-control-label" htmlFor="top">Топ</label>
                </div>
                <div className={"col-2 text-center stock custom-control custom-switch"}>
                  <input type="checkbox" className="custom-control-input" id="latest"
                         onChange={this.LatestChange} defaultChecked={false}/>
                  <label className="custom-control-label" htmlFor="latest">Новинка</label>
                </div>
              </div>
              <textarea name="description" value={this.state.description} onChange={this.DescriptionChange}
                        placeholder="Описание [до 500 символов] - необязательно"
                        className={"form-control"} maxLength="500"/>
              <div className={"row"}>
                <div className={"col-6"}>
                  <input name="price" type="text" value={this.state.price} onChange={this.PriceChange}
                         placeholder="Цена [ 10.00 ] - необязательно"
                         className={"form-control"} maxLength="10"/>
                </div>
                <div className={"col-6"}>
                  <input name="code" type="text" value={this.state.code} onChange={this.CodeChange}
                         placeholder="Код товара [ 0000111 ] - необязательно"
                         className={"form-control"} maxLength="30"/>
                </div>
              </div>
              <div className={"row"}>
                <div className={"col-6"}>
                  <select name="category" value={this.state.category} onChange={this.CategoryChange}
                          className={"form-control select-control"}
                          required={true}>
                    <option key={"1"} defaultValue={true}>Выберите категорию [обязательно]</option>
                    {ItemCategory}
                  </select>
                </div>
                <div className={"col-6"}>
                  <select name="manufacturers" value={this.state.manufacturers}
                          onChange={this.ManufacturersChange}
                          className={"form-control select-control"} required={true}>
                    <option key={"1"} defaultValue={true}>Выберите производителя [обязательно]</option>
                    {ItemManufacturer}
                  </select>
                </div>
              </div>
              <div className={"row files"}>{ItemFileButton}</div>
              <div className="row" style={{marginTop: "5px"}}>
                <div className="col-8">
                  <small>* Обязательно к добавлению хотя бы одно фото, начиная с левой стороны</small>
                </div>
                <div className="col-4 text-right">
                  <label className="label"
                         style={{backgroundColor: "grey", padding: "5px 10px", borderRadius: "5px"}}>
                    Открыть SEO-форму
                    <input className="input" type="checkbox" checked={isOpened}
                           onChange={({target: {checked}}) => this.setState({isOpened: checked})}
                           style={{display: "none"}}/>
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <Collapse isOpened={isOpened}>
                    <input name="seo_title" type="text" value={this.state.seoTitle}
                           onChange={this.seoTitleChange}
                           placeholder="SEO title [до 100 символов]"
                           className={"form-control"}
                           maxLength="100"/>
                    <textarea name="seo_description" value={this.state.seoDescription}
                              onChange={this.seoDescriptionChange}
                              placeholder="SEO description [до 500 символов]"
                              className={"form-control"} maxLength="500"/>
                    <input name="seo_keywords" type="text" value={this.state.seoKeywords}
                           onChange={this.seoKeywordsChange}
                           placeholder="SEO keywords [до 100 символов]"
                           className={"form-control"}
                           maxLength="100"/>
                  </Collapse>
                </div>
              </div>
            </div>
            <button type="submit" className={"btn btn-main btn-lg btn-block"}
                    onClick={(e) => {
                      document.querySelector(".btn-main").style.backgroundColor = "green"
                      document.querySelector(".btn-main").innerHTML = "Отправляю..."
                    }}>Сохранить
            </button>
          </form>
        </Fragment>
    )
  }
}

const mapStateToProps = ({Category, Manufacturers}) => {
  return {
    CategoryRequest: Category.request,
    CategoryData: Category.data,
    CategoryError: Category.error,
    ManufacturersRequest: Manufacturers.request,
    ManufacturersData: Manufacturers.data,
    ManufacturersError: Manufacturers.error,
  }
}

export default connect(mapStateToProps, null)(AddProductForm)
