// Import React Library
import React, {Component, Fragment, lazy, Suspense} from "react"
import {connect} from "react-redux"
import {SmallLoader} from "../../components/preloader"
import {GET_MANUFACTURERS_REQUEST, LAST_PRODUCT} from "../../actions/actionCreator"
import {goToAnchor, removeHash} from "react-scrollable-anchor"
import {getProductsByManufacturersAPI} from "../../api"
import {NavLink} from "react-router-dom"
import Footer from "../../components/footer"
import Masonry from "react-masonry-css"
import {Collapse} from 'react-collapse'

export const Header = lazy(() => import("../../components/header"))
export const Menu = lazy(() => import("../../components/menu"))

const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1,
}

class Manufacturer extends Component {

    state = {
        manufacturer: this.props.location.pathname.split("/")[2],
        products: [],
    }

    componentDidMount() {
        this.props.dispatch(GET_MANUFACTURERS_REQUEST())
        getProductsByManufacturersAPI({manufacturer: this.state.manufacturer}).then((result) => {
            this.setState({products: result})
        })
        goToAnchor("beginProducts")
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.manufacturer !== this.props.location.pathname.split("/")[2]) {
            this.setState({manufacturer: this.props.location.pathname.split("/")[2]})
            getProductsByManufacturersAPI({manufacturer: this.props.location.pathname.split("/")[2]}).then((result) => {
                this.setState({products: result})
            })
            goToAnchor("beginProducts")
        }
    }

    renderSwitch(param) {
        switch (param) {
            case 'goshchanski_kovbasi':
                return 'goshchanski_kovbasi'
            case 'khutoryanka':
                return 'khutoryanka'
            case 'korist':
                return 'korist'
            case 'stovpinski_kovbasi':
                return 'stovpinski_kovbasi'
            case 'poliski_kovbasi_pp_kolyadyuk':
                return 'poliski_kovbasi_pp_kolyadyuk'
            case 'kalina':
                return 'kalina'
            case 'khodorivskii_mk':
                return 'khodorivskii_mk'
            case 'gayar':
                return 'gayar'
            case 'zhadkivski_kovbasi':
                return 'zhadkivski_kovbasi'
            case 'tovari_z_evropi':
                return 'tovari_z_evropi'
            case 'barvinok':
                return 'barvinok'
            case 'voskodavski_kovbasi':
                return 'voskodavski_kovbasi'
            case 'tinnivski_kovbasi_pp_kaminskii':
                return 'tinnivski_kovbasi_pp_kaminskii'
            case 'stemp':
                return 'stemp'
            case 'verbena':
                return 'verbena'
            case 'zakarpatski_kovbasi':
                return 'zakarpatski_kovbasi'
            case 'oleksandriiski_kovbasi':
                return 'oleksandriiski_kovbasi'
            case 'gorodochok':
                return 'gorodochok'
            case 'pp_sadovskii_vi':
                return 'pp_sadovskii_vi'
            case 'tarasivski_kovbasi':
                return 'tarasivski_kovbasi'
            case 'makogon':
                return 'makogon'
            case 'gaisinskii_molokozavod':
                return 'gaisinskii_molokozavod'
            case 'pp_voznyuk':
                return 'pp_voznyuk'
            case 'goshchanochka':
                return 'goshchanochka'
            case 'moya_kovbaska':
                return 'moya_kovbaska'
            case 'dmitruk':
                return 'dmitruk'
            case 'lutskii_mk':
                return 'lutskii_mk'
            case 'lviv':
                return 'lviv'
            case 'monashinski_siri':
                return 'monashinski_siri'
            case 'mikhodi':
                return 'mikhodi'
            case 'tuchinski_kovbasi':
                return 'tuchinski_kovbasi'
            case 'brashno':
                return 'brashno'
            case 'sobush':
                return 'sobush'
            case 'adamivski_kovbasi':
                return 'adamivski_kovbasi'
            case 'rusalina':
                return 'rusalina'
            case 'ryasniki':
                return 'ryasniki'
            case 'saltiskii_mk':
                return 'saltiskii_mk'
            case 'bogodukhivskii_mk':
                return 'bogodukhivskii_mk'
            case 'striivetski_kovbasi':
                return 'striivetski_kovbasi'
            case 'sofiya':
                return 'sofiya'
            case 'ukrainskii_mk':
                return 'ukrainskii_mk'
            case 'ivankovetski_kovbasi_iko':
                return 'ivankovetski_kovbasi_iko'
            case 'krupelnitskii':
                return 'krupelnitskii'
            case 'tov_«start»':
                return 'tov_«start»'
            case 'ternopilskii_mk':
                return 'ternopilskii_mk'
            case 'chaika':
                return 'chaika'
            case 'inshi':
                return 'inshi'
            default:
                return 'foo'
        }
    }


    render() {
        const {Manufacturers} = this.props
        let array, Item, manufacturer = this.state.manufacturer, ItemProduct
        if (!Manufacturers) return <Fragment>
            <Suspense fallback={<SmallLoader/>}>
                <Header/>
            </Suspense>
            <Suspense fallback={<SmallLoader/>}>
                <Menu/>
            </Suspense>
            <div className="container-fluid  mrg">
                <SmallLoader/>
            </div>
            <Footer/>
        </Fragment>

        array = Manufacturers.filter(item => item.translit === manufacturer)
        if (!array.length) Item = <div>Немає виробника</div>
        Item = array.map((item) => {
            return <div key={item._id}>{item.name}</div>
        })

        if (!this.state.products.length) ItemProduct = <div className="no-product"/>

        ItemProduct = this.state.products.map((item) => {
            return <section id={item.translit}>
                <div itemScope itemType="http://schema.org/ItemList">
                    <div itemProp="itemListElement" itemScope itemType="http://schema.org/Product">
                        <NavLink itemProp="url"
                                 to={`/product/${item.category}/${item.manufacturers}/${item.translit}/`}
                                 onClick={() => {
                                     this.props.dispatch(LAST_PRODUCT({lastProduct: item.translit}))
                                 }}>
                            <div
                                className={item.status ? "card text-center" : "card text-center grayscale"}>
                                <picture>
                                    <source
                                        srcSet={`/uploads/${item.category}/${item.translit}/${item.translit}_1.webp`}
                                        type="image/webp"/>
                                    <source
                                        srcSet={`/uploads/${item.category}/${item.translit}/${item.translit}_1.png`}
                                        type="image/png"/>
                                    <img
                                        src={`/uploads/${item.category}/${item.translit}/${item.translit}_1.webp`}
                                        alt={item.translit} className="card-img-top"
                                        itemProp="image"/>
                                </picture>
                                <div className="card-body">
                                    <div className="card-title text-center">
                                        <span itemProp="manufacturer">{item.manufacturers_cyr}</span>
                                        <br/>
                                        "<span itemProp="name">{item.name}"</span></div>
                                </div>
                                <div className="card-footer">
                                    <div className="text-center">
                                        <div itemProp="offers" itemScope itemType="http://schema.org/Offer">
                                            {item.status
                                                ? <div>{item.price ? <div><strong itemProp="price"
                                                                                  content={item.price}>{item.price}</strong> грн
                                                    </div> :
                                                    <div>Уточнюйте по телефону</div>}</div>
                                                : "Немає в наявності"}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </NavLink>
                    </div>
                </div>
            </section>
        })

        if (this.props.LastProduct.lastProduct) {
            setTimeout(() => {
                goToAnchor(this.props.LastProduct.lastProduct)
                this.props.dispatch(LAST_PRODUCT({lastProduct: null}))
                removeHash()
            }, 700)
        }

        if (this.state.products.length) {
            document.title = `Купити продукцію торгової марки ${array[0].name} оптом Україна`
            document.getElementsByTagName(
                "META")[3].content = `${array[0].name}, продукція ${array[0].name}, ${array[0].name} купити, ${array[0].name} Україна, ${array[0].name} товари, ${array[0].name} каталог товарів`
            document.getElementsByTagName(
                "META")[4].content = `Купити продукцію ${array[0].name} оптом та в роздріб Україна. Каталог товарів ${array[0].name}.`
        }

        return <Fragment>
            <Suspense fallback={<SmallLoader/>}>
                <Header/>
            </Suspense>
            <Suspense fallback={<SmallLoader/>}>
                <Menu/>
            </Suspense>
            <Collapse isOpened={false}>
                <div className="seo__description__text">
                    <p>{this.renderSwitch(manufacturer)}</p>
                </div>
            </Collapse>
            <div className="container-fluid  mrg">
                <section id={"beginProducts"}/>
                <div className="ProductList">
                    <div className="title" style={{textAlign: "center"}}>{Item}</div>
                    <div className="card-deck">
                        <Masonry
                            breakpointCols={breakpointColumnsObj}
                            className="my-masonry-grid"
                            columnClassName="my-masonry-grid_column"
                        >{ItemProduct}
                        </Masonry>
                    </div>
                </div>
            </div>
            <Footer/>
        </Fragment>
    }
}

const mapStateToProps = ({Manufacturers, LastProduct}) => {
    return {
        ManufacturersRequest: Manufacturers.request,
        Manufacturers: Manufacturers.data,
        ManufacturersError: Manufacturers.error,
        LastProduct: LastProduct,
    }
}

export default connect(mapStateToProps, null)(Manufacturer)
