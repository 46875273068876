// Import React Library
import React, {Fragment} from 'react'
import Header from '../../components/header'
import Footer from '../../components/footer'
import ImageGallery from 'react-image-gallery'

const galleryImages = [
    {
        original: '/gallery/original/1.jpg',
        thumbnail: '/gallery/thumbnail/1.jpg',
        description: 'Швидка та якiсна доставка',
    },
    {
        original: '/gallery/original/2.jpg',
        thumbnail: '/gallery/thumbnail/2.jpg',
    },
    {
        original: '/gallery/original/3.jpg',
        thumbnail: '/gallery/thumbnail/3.jpg',
    },
    {
        original: '/gallery/original/4.jpg',
        thumbnail: '/gallery/thumbnail/4.jpg',
    },
    {
        original: '/gallery/original/5.jpg',
        thumbnail: '/gallery/thumbnail/5.jpg',
    },
    {
        original: '/gallery/original/6.jpg',
        thumbnail: '/gallery/thumbnail/6.jpg',
    },
    {
        original: '/gallery/original/7.jpg',
        thumbnail: '/gallery/thumbnail/7.jpg',
    },
    {
        original: '/gallery/original/8.jpg',
        thumbnail: '/gallery/thumbnail/8.jpg',
    },
    {
        original: '/gallery/original/9.jpg',
        thumbnail: '/gallery/thumbnail/9.jpg',
    },
    {
        original: '/gallery/original/10.jpg',
        thumbnail: '/gallery/thumbnail/10.jpg',
    }, {
        original: '/gallery/original/11.jpg',
        thumbnail: '/gallery/thumbnail/11.jpg',
    },
    {
        original: '/gallery/original/12.jpg',
        thumbnail: '/gallery/thumbnail/12.jpg',
    },
    {
        original: '/gallery/original/13.jpg',
        thumbnail: '/gallery/thumbnail/13.jpg',
    },
    {
        original: '/gallery/original/14.jpg',
        thumbnail: '/gallery/thumbnail/14.jpg',
    },
    {
        original: '/gallery/original/15.jpg',
        thumbnail: '/gallery/thumbnail/15.jpg',
    },
    {
        original: '/gallery/original/18.jpg',
        thumbnail: '/gallery/thumbnail/18.jpg',
    },
]

export default class AboutPage extends React.Component {

    componentDidMount() {
        document.title = `Миходi Рівне доставка ковбасної та м'ясної продукції у Луцьк, Київ, Хмельницьк, Житомир, Тернопіль, Львів, Ужгород, Івано-Франківськ, Вінниця`
        document.getElementsByTagName(
            'META')[3].content = `Миходi Рівне купити ковбасну та м'ясну продукцію з доставкою у Луцьк, Київ, Хмельницьк, Житомир, Тернопіль, Львів, Ужгород, Івано-Франківськ, Вінницю`
        document.getElementsByTagName('META')[4].content = `ковбаса рівне, ковбаса оптом, купити ковбасу, миході рівне, ковбасні вироби, виготовлення ковбаси, ковбаса від виробника, рибна продукція, копчені м'яса`
    }

    render() {
        return (
            <Fragment>
                <Header/>
                <div className="container-fluid">
                    <div className="about-us">
                        <div className="why-us">
                            <div className="row">
                                <div className="col-12 col-md-3 text-center" style={{margin: '10px 0'}}>
                                    <div className="text-center" style={{margin: '20px 0'}}><img
                                        src={"./images/salami.svg"} alt="" height={"50"}/></div>
                                    Продукція від 50 виробників
                                </div>
                                <div className="col-12 col-md-3 text-center" style={{margin: '10px 0'}}>
                                    <div className="text-center" style={{margin: '20px 0'}}><img
                                        src={"./images/stand.svg"} alt="" height={"50"}/></div>
                                    12 років якісних послуг в Україні
                                </div>
                                <div className="col-12 col-md-3 text-center" style={{margin: '10px 0'}}>
                                    <div className="text-center" style={{margin: '20px 0'}}><img
                                        src={"./images/moving-truck.svg"} alt="" height={"50"}/></div>
                                    Більше 1000 товарів в асортименті
                                </div>
                                <div className="col-12 col-md-3 text-center" style={{margin: '10px 0'}}>
                                    <div className="text-center" style={{margin: '20px 0'}}><img
                                        src={"./images/knife.svg"} alt="" height={"50"}/></div>
                                    Свіжий товар протягом усього тижня
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-7 col-lg-8 text-center" style={{margin: '10px 0'}}>
                                <div className="more">
                                    <p className="text-center"><strong>Миходi™ - офіційний представник понад 50
                                        найбільших виробників зі всієї України.</strong></p>

                                    <p>Ми успішно реалізуємо м'ясну продукцію починаючи з 2008 року. Завдяки перевіреним
                                        підприємствам і регулярним поставкам впродовж
                                        усього тижня наші товари завжди свіжі і якісні.</p>

                                    <p>За час існування ми встигли завоювати довіру сотень постійних оптових клієнтів.
                                        Наш асортимент включає більше 1000 позицiй та
                                        постiйно оновлюється.</p>

                                    <p>У своїй роботі Миходi™ керується строгими правилами та дотримується стандартiв
                                        харчової етики, безпеки і прозорості на всіх
                                        етапах.</p>

                                    <p>Доставка товарів здійснюється спеціалізованим транспортом з холодильними
                                        установками. Наші співробітники - професіонали своєї
                                        справи. Вони підготують все так, як Вам потрібно, і Ви отримаєте товар без
                                        зайвих турбот. До того ж, Миходi™ має власну мережу
                                        магазинів, і ми готові в індивідуальному порядку зробити аналіз і рекомендувати
                                        продукцію для початківців. Наш багаторічний досвід
                                        допоможе Вам на стартi власного бізнесу.</p>

                                    <p>А завдяки нашому інтернет-магазину Ви можете постійно бути в курсі всього
                                        асортименту, цін та наявності продукції, а також знижок,
                                        і ми знаємо, як це важливо. А ще, у нас дуже хороша система індивідуальних
                                        знижок для кожного оптового клієнта. Цікаво? Тоді
                                        телефонуйте, і ми обговоримо деталі. Співпраця з нами принесе Вам позитивні
                                        емоції, і не тільки ;)</p>

                                    <p>Якщо Ви знайшли бажані товари в нашому асортименті або хотіли б отримувати
                                        доставку в місто, якого нема у розділі "Доставка",
                                        повідомте нам, і ми це виправимо. Наше головне завдання - щоб Ви і Ваші покупці
                                        були задоволені, адже це запорука успішної співпраці
                                        і головна мета нашої компанії.</p>
                                </div>
                            </div>
                            <div className="col-12 col-md-5 col-lg-4 text-center" style={{margin: '10px 0'}}>
                                <div className={"fb_widget"}>
                                    <p className="text-center"><strong>Миходi на Facebook</strong></p>
                                    <div className="fb-page" data-href="https://www.facebook.com/mihodi.rivne/"
                                         data-tabs="timeline,messages,events" data-width="800" data-height=""
                                         data-small-header="false" data-adapt-container-width="true"
                                         data-hide-cover="false"
                                         data-show-facepile="true" data-lazy="true">
                                        <blockquote cite="https://www.facebook.com/mihodi.rivne/"
                                                    className="fb-xfbml-parse-ignore"><a
                                            href="https://www.facebook.com/mihodi.rivne/">Миході</a></blockquote>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 text-center" style={{margin: '10px 0'}}>
                                <div className={"gallery"}>
                                    <ImageGallery lazyLoad={true} autoPlay={true} showNav={false} showPlayButton={false}
                                                  showBullets={false} thumbnailPosition="left" items={galleryImages}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </Fragment>
        )
    }
}
