import Axios from 'axios'

class ApiRequest {

  /**
   *
   * @param url
   * @param headers
   * @param params
   * @returns {Promise<T>}
   * @constructor
   */

  GET (url, headers, ...params) {
    return Axios.get(url, headers).then(({ data }) => {
      return data
    }).catch((error) => {
      return error
    })
  }

  /**
   *
   * @param url
   * @param data
   * @param headers
   * @param params
   * @returns {Promise<T>}
   * @constructor
   */

  POST (url, data, headers, ...params) {
    return Axios.post(url, data, headers).then(({ data }) => {
      return data
    }).catch((error) => {
      return error
    })
  }

  /**
   *
   * @param url
   * @param data
   * @returns {Promise<T>}
   * @constructor
   */

  DELETE (url, data) {
    return Axios.delete(url, { data }).then(({ data }) => {
      return data
    }).catch((error) => {
      return error
    })
  }

  /**
   * 
   * @param params
   * @returns {Promise<T[]>}
   * @constructor
   */

  ALL (...params) {
    return Axios.all([
      Axios.get(params[0]),
      Axios.get(params[1]),
    ]).then(responseArr => {
      console.log('Date created: ', responseArr[0].data)
      console.log('Date created: ', responseArr[1].data)
    })
  }

}

export default new ApiRequest()