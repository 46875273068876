// Import React Library
import React, {Component, Fragment, lazy, Suspense} from "react"
import {connect} from "react-redux"
import {SmallLoader} from "../../components/preloader"
import {GET_CATEGORIES_REQUEST, LAST_PRODUCT} from "../../actions/actionCreator"
import {goToAnchor, removeHash} from "react-scrollable-anchor"
import {getProductsByCategoryAPI} from "../../api"
import {NavLink} from "react-router-dom"
import Footer from "../../components/footer"
import Masonry from "react-masonry-css"

export const Header = lazy(() => import("../../components/header"))
export const Menu = lazy(() => import("../../components/menu"))


const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1,
}

class Category extends Component {

    state = {
        category: this.props.location.pathname.split("/")[2],
        products: [],
    }

    componentDidMount() {
        this.props.dispatch(GET_CATEGORIES_REQUEST())
        getProductsByCategoryAPI({category: this.state.category}).then((result) => {
            this.setState({products: result})
        })
        goToAnchor("beginProducts")
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.category !== this.props.location.pathname.split("/")[2]) {
            this.setState({category: this.props.location.pathname.split("/")[2]})
            getProductsByCategoryAPI({category: this.props.location.pathname.split("/")[2]}).then((result) => {
                this.setState({products: result})
            })
            goToAnchor("beginProducts")
        }
    }

    render() {
        const {Category} = this.props
        let array, Item, category = this.state.category, ItemProduct
        if (!Category) return <Fragment>
            <Suspense fallback={<SmallLoader/>}>
                <Header/>
            </Suspense>
            <Suspense fallback={<SmallLoader/>}>
                <Menu/>
            </Suspense>
            <div className="container-fluid">
                <SmallLoader/>
            </div>
            <Footer/>
        </Fragment>

        array = Category.filter(item => item.translit === category)
        if (!array.length) Item = <div>Немає категорії</div>

        Item = array.map((item) => {
            return <div key={item._id}>{item.name}</div>
        })


        ItemProduct = this.state.products.map((item) => {
            return <section id={item.translit}>
                    <div itemScope itemType="http://schema.org/ItemList">
                        <div itemProp="itemListElement" itemScope itemType="http://schema.org/Product">
                            <NavLink itemProp="url"
                                     to={`/product/${item.category}/${item.manufacturers}/${item.translit}/`}
                                     onClick={() => {
                                         this.props.dispatch(LAST_PRODUCT({lastProduct: item.translit}))
                                     }}>
                                <div className={item.status ? "card text-center" : "card text-center grayscale"}>
                                    <picture>
                                        <source
                                            srcSet={`/uploads/${item.category}/${item.translit}/${item.translit}_1.webp`}
                                            type="image/webp"/>
                                        <source
                                            srcSet={`/uploads/${item.category}/${item.translit}/${item.translit}_1.png`}
                                            type="image/png"/>
                                        <img
                                            src={`/uploads/${item.category}/${item.translit}/${item.translit}_1.webp`}
                                            alt={item.translit} className="card-img-top"
                                            itemProp="image"/>
                                    </picture>
                                    <div className="card-body">
                                        <div className="card-title text-center"><span
                                            itemProp="manufacturer">{item.manufacturers_cyr}</span>
                                            <br/>
                                            "<span itemProp="name">{item.name}"</span></div>
                                    </div>
                                    <div className="card-footer">
                                        <div className="text-center">
                                            <div itemProp="offers" itemScope itemType="http://schema.org/Offer">
                                                {item.status
                                                    ? <div>{item.price ? <div><strong itemProp="price"
                                                                                      content={item.price}>{item.price}</strong> грн
                                                        </div> :
                                                        <div>Уточнюйте по телефону</div>}</div>
                                                    : "Немає в наявності"}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                </section>
        })

        if (this.props.LastProduct.lastProduct) {
            setTimeout(() => {
                goToAnchor(this.props.LastProduct.lastProduct)
                this.props.dispatch(LAST_PRODUCT({lastProduct: null}))
                removeHash()
            }, 700)
        }
        if (this.state.products.length) {
            document.title = `${array[0].name} купити оптом та в роздріб Миході Рівне Україна`
            document.getElementsByTagName(
                "META")[3].content = `${array[0].name}, ${array[0].name} купити оптом та в роздріб, ${array[0].name} Миходi Рівне, ${array[0].name} купити Україна`
            document.getElementsByTagName(
                "META")[4].content = `${array[0].name} купити оптом, ${array[0].name} миході рівне, ${array[0].name} каталог, ${array[0].name} ціна, ${array[0].name} Україна`
        }

        return <Fragment>
            <Suspense fallback={<SmallLoader/>}>
                <Header/>
            </Suspense>
            {/*<MainBg/>*/}
            <Suspense fallback={<SmallLoader/>}>
                <Menu/>
            </Suspense>
            <div className="container-fluid  mrg" style={{marginTop: "25px"}}>
                <section id={"beginProducts"}/>
                <div className="ProductList">
                    <div className="title" style={{textAlign: "center"}}>{Item}</div>
                    <div className="card-deck" style={{textAlign: "center"}}>
                        <Masonry
                            breakpointCols={breakpointColumnsObj}
                            className="my-masonry-grid"
                            columnClassName="my-masonry-grid_column"
                        >
                        {(this.state.products.length ? ItemProduct : <div className="no-product"/>)}
                        </Masonry>
                    </div>
                </div>
            </div>
            <Footer/>
        </Fragment>

    }
}

const mapStateToProps = ({Category, LastProduct, Filters}) => {
    return {
        CategoryRequest: Category.request,
        Category: Category.data,
        CategoryError: Category.error,
        LastProduct: LastProduct,
    }
}

export default connect(mapStateToProps, null)(Category)
