import React, {Component, Fragment, lazy, Suspense} from "react"
import {NavLink} from "react-router-dom"
import {connect} from "react-redux"
import {GET_STOCK_PRODUCTS_REQUEST} from "../../../actions/actionCreator"
import {SmallLoader} from "../../../components/preloader"
import Loader from "../../loader"
import Masonry from "react-masonry-css"

export const Header = lazy(() => import("../../../components/header"))
export const Menu = lazy(() => import("../../../components/menu"))
export const Footer = lazy(() => import("../../../components/footer"))

const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1,
}

class StockProducts extends Component {

    componentDidMount() {
        this.props.dispatch(GET_STOCK_PRODUCTS_REQUEST())
        document.title = "Ковбаса та м'ясна продукція по акційним оптовим цінам Миході Рівне Україна"
        document.getElementsByTagName(
            "META")[3].content = "Акційні товари ковбас та м'яса по оптовим цінам на Миході Рівне"
        document.getElementsByTagName("META")[4].content = "акційні товари, ковбаса по акції, знижки на ковбасу, ковбаса оптом по акції, акції миході, акції рівне"
    }

    render() {
        const {stockProducts} = this.props

        if (!stockProducts) return <Loader/>

        const Item = stockProducts.map((item) => {
            return <section id={item.translit}>
                <div itemScope itemType="http://schema.org/ItemList">
                        <div itemProp="itemListElement" itemScope itemType="http://schema.org/Product">
                            <NavLink itemProp="url"
                                     to={`/product/${item.category}/${item.manufacturers}/${item.translit}/`}>
                                <div className={item.status ? "card text-center" : "card text-center grayscale"}>
                                    <picture>
                                        <source
                                            srcSet={`/uploads/${item.category}/${item.translit}/${item.translit}_1.webp`}
                                            type="image/webp"/>
                                        <source
                                            srcSet={`/uploads/${item.category}/${item.translit}/${item.translit}_1.png`}
                                            type="image/png"/>
                                        <img src={`/uploads/${item.category}/${item.translit}/${item.translit}_1.webp`}
                                             alt={item.translit} className="card-img-top"
                                             itemProp="image"/>
                                    </picture>
                                    <div className="card-body">
                                        <div className="card-title text-center"><span
                                            itemProp="manufacturer">{item.manufacturers_cyr}</span>
                                            <br/>
                                            "<span itemProp="name">{item.name}"</span></div>
                                    </div>
                                    <div className="card-footer">
                                        <div className="text-center">
                                            <div itemProp="offers" itemScope itemType="http://schema.org/Offer">
                                                {item.status
                                                    ? <div>{item.price ? <div><strong itemProp="price"
                                                                                      content={item.price}>{item.price}</strong> грн
                                                        </div> :
                                                        <div>Уточнюйте по телефону</div>}</div>
                                                    : "Немає в наявності"}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                    </div>
            </section>
        })

        return <Fragment>
            <Suspense fallback={<SmallLoader/>}>
                <Header/>
            </Suspense>
            <Suspense fallback={<SmallLoader/>}>
                <Menu/>
            </Suspense>
            <div className="container-fluid mrg">
                <div className={"stock"}>
                    <h2 className="title text-center">Акційний товар</h2>
                    <div className="row">
                        <div className="card-deck">
                            <Masonry
                                breakpointCols={breakpointColumnsObj}
                                className="my-masonry-grid"
                                columnClassName="my-masonry-grid_column"
                            >
                            {Item}
                            </Masonry>
                        </div>
                    </div>
                </div>
            </div>
            <Suspense fallback={<SmallLoader/>}>
                <Footer/>
            </Suspense>
        </Fragment>
    }
}

const mapStateToProps = ({stockProducts}) => {
    return {
        stockProductsRequest: stockProducts.request,
        stockProducts: stockProducts.data,
        stockProductsError: stockProducts.error,
    }
}

export default connect(mapStateToProps, null)(StockProducts)
