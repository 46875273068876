const initialState = {
  name: "",
}
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case "ACTIVE_CATEGORY":
      return action.payload
    default:
      return state
  }
};
export const activeCategory = state => state
