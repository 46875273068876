// Import React Library
import React, {Suspense} from "react"
import Header from "../../components/header"
import MainAbout from "./about"
import MainBg from "./mainBg"
import Footer from "../../components/footer"
import Search from "../../components/search"
import Menu from "../../components/menu"
import CategoriesBlock from "../../components/menu/categories/blocks"
import {SmallLoader} from "../../components/preloader"

export default class MainPage extends React.Component {

    componentDidMount() {
        document.title = "М'ясні та ковбасні вироби оптом та в роздріб Україна - купити ковбасу Миході Рівне"
        document.getElementsByTagName(
            "META")[3].content = "М'ясні та ковбасні вироби оптом та в роздріб Україна - Миході. Купити ковбасу Рівне, Луцьк, Київ, Хмельницький, Житомир, Тернопіль, Львів, Ужгород, Івано-Франківськ, Вінниця"
        document.getElementsByTagName("META")[4].content = "ковбаса рівне, ковбаса оптом, купити ковбасу, миході рівне, ковбасні вироби, виготовлення ковбаси, ковбаса від виробника, рибна продукція, копчені м'яса"
    }

    render() {
        return <Suspense fallback={<SmallLoader/>}>
            <Header/>
            <MainBg/>
            <div>
                <Search/>
                <Menu/>
                <CategoriesBlock/>
                <MainAbout/>
            </div>
            <Footer/>
        </Suspense>
    }
}
