// Import React Library
import React, {lazy, Suspense} from "react"
import {NavLink} from "react-router-dom"
import {SmallLoader} from "../preloader"

export const Manufacturers = lazy(() => import("./manufacturers"))
export const Categories = lazy(() => import("./categories"))

export default class Menu extends React.Component {
    render() {
        const path = window.location.pathname
        return <div className="container-fluid" style={{width: "95%"}}>
            <div className="mainMenu">
                <div className="row">
                    <div className="col-12">
                        <div className="filters">
                            <div className="row">
                                <Suspense fallback={<SmallLoader/>}>
                                    <div className="col-12 col-lg-3 text-center text-lg-left">
                                        <Manufacturers/>
                                    </div>
                                </Suspense>
                                <Suspense fallback={<SmallLoader/>}>
                                    <div className="col-12 col-lg-3 text-center text-lg-left">
                                        <Categories/>
                                    </div>
                                </Suspense>
                                <div className="col-6 col-lg-3 text-center text-lg-left">
                                    <NavLink to={"/latest"} exact={true} activeClassName="">
                                        {(path === "/latest") ? <div className="latest active">Новинки</div> :
                                            <div className="latest">Новинки</div>}
                                    </NavLink>
                                </div>
                                {/*<NavLink to={"/top"} exact={true} activeClassName="" className="col-4 col-lg-12 text-center text-lg-left">*/}
                                {/*  {(path === "/top") ? <div className="top active">Топ продаж</div> : <div className="top">Топ продаж</div>}*/}
                                {/*</NavLink>*/}
                                <div className="col-6 col-lg-3 text-center text-lg-left">
                                    <NavLink to={"/stock"} exact={true} activeClassName="">
                                        {(path === "/stock") ? <div className="stock active">Акційні</div> :
                                            <div className="stock">Акційні</div>}
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}
