// Import React Library
import React, { Fragment } from "react"
import Header from "../../components/header"
import MenuTabs from "./menu"
import AddManufacturersForm from "./forms/addManufacturersForm"
import { addManufacturersAPI, deleteManufacturersAPI, rateManufacturersAPI, isAdminAPI } from "../../api"
import { connect } from "react-redux"
import { GET_MANUFACTURERS_REQUEST, IS_ADMIN } from "../../actions/actionCreator"
import IsAdmin from "./isAdmin"

// https://www.npmjs.com/package/react-drag-listview
import ReactDragListView from "react-drag-listview"

class ControlManufacturers extends React.Component {

  state = {
    data: null,
    fill: true,
  }

  componentDidMount () {
    document.title = "Панель менеджера - Производители"
    isAdminAPI().then(result => {
      if (result.status) {
        this.props.dispatch(IS_ADMIN(true))
        this.props.dispatch(GET_MANUFACTURERS_REQUEST())
      }
    })
  }

  componentDidUpdate (prevProps, prevState, snapshot) {}

  DeleteManufacturer = (id) => {
    deleteManufacturersAPI({ id }).then(result => {
      let informerClass = document.querySelector(".informer")
      if (result.response.success) {
        informerClass.classList.add("success", "animated", "zoomIn")
        informerClass.classList.remove("d-none")
        informerClass.innerHTML = result.response.success.msg
        setTimeout(() => {
          informerClass.classList.add("d-none")
          informerClass.classList.remove("success", "animated", "zoomIn")
        }, 5000)
        this.props.dispatch(GET_MANUFACTURERS_REQUEST())
      }
      if (result.response.error) {
        informerClass.classList.add("error", "animated", "zoomIn")
        informerClass.classList.remove("d-none")
        informerClass.innerHTML = result.response.error.msg
        setTimeout(() => {
          informerClass.classList.add("d-none")
          informerClass.classList.remove("success", "animated", "zoomIn")
        }, 5000)
      }
    })
  }

  submit = values => {
    addManufacturersAPI(values).then(result => {
        let informerClass = document.querySelector(".informer")
        if (result.response.success) {
          informerClass.classList.add("success", "animated", "zoomIn")
          informerClass.classList.remove("d-none")
          informerClass.innerHTML = result.response.success.msg
          setTimeout(() => {
            informerClass.classList.add("d-none")
            informerClass.classList.remove("success", "animated", "zoomIn")
          }, 5000)
          this.props.dispatch(GET_MANUFACTURERS_REQUEST())
        }
        if (result.response.error) {
          informerClass.classList.add("error", "animated", "zoomIn")
          informerClass.classList.remove("d-none")
          informerClass.innerHTML = result.response.error.msg
          setTimeout(() => {
            informerClass.classList.add("d-none")
            informerClass.classList.remove("success", "animated", "zoomIn")
          }, 5000)
        }
      },
    )
  }

  render () {

    const { ManufacturersRequest, ManufacturersData, isAdmin } = this.props

    if (!isAdmin) return <IsAdmin/>

    if (ManufacturersRequest) return "Завантаження..."

    let manufacturers = []
    if (ManufacturersData) manufacturers = ManufacturersData

    if (manufacturers.length && this.state.fill) this.setState({ data: manufacturers, fill: false })
    if (!this.state.data) return "Завантаження..."

    const that = this
    const dragProps = {
      onDragEnd (fromIndex, toIndex) {
        const { data } = that.state
        const item = data.splice(fromIndex, 1)[0]
        data.splice(toIndex, 0, item)
        that.setState({ data })
        rateManufacturersAPI({ data }).then(result => {
          that.props.dispatch(GET_MANUFACTURERS_REQUEST())
        })
      },
      nodeSelector: "li",
      handleSelector: "a",
    }

    const ItemManufacturer = this.state.data.map((item, index) => {
      // eslint-disable-next-line
      return (<li className={"col-12"} key={item._id}><a href="#">{item.name}</a> <span
        onDoubleClick={() => {this.DeleteManufacturer(item._id)}}>Видалити</span></li>)
    })

    return <Fragment>
      <Header/>
      <div className="container-fluid">
        <div className="control-panel-manufacturers">
          <div className="container">
            <MenuTabs/>
            <h2 className={"title text-center"}>Виробники</h2>
            <div className="informer d-none"/>
            <div className="add-manufacturers-form">
              <AddManufacturersForm onSubmit={this.submit}/>
            </div>
            <div className="manufacturers">
              <ReactDragListView {...dragProps}>
                <ol>{ItemManufacturer}</ol>
              </ReactDragListView>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  }
}

const mapStateToProps = ({ Manufacturers, isAdmin }) => {
  return {
    isAdmin: isAdmin,
    ManufacturersRequest: Manufacturers.request,
    ManufacturersData: Manufacturers.data,
    ManufacturersError: Manufacturers.error,
  }
}

export default connect(mapStateToProps, null)(ControlManufacturers)
