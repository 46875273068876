// Import React Library
import React, { Fragment } from "react"
import AddProductForm from "./forms/addProductForm"
import Header from "../../components/header"
import Footer from "../../components/footer"
import MenuTabs from "./menu"
import { connect } from "react-redux"
import IsAdmin from "./isAdmin"
import { isAdminAPI } from "../../api"
import { IS_ADMIN } from "../../actions/actionCreator"

class ControlAddProduct extends React.Component {

  componentDidMount () {
    document.title = "Панель менеджера - Добавить продукт"
    isAdminAPI().then(result => {
      if (result.status) this.props.dispatch(IS_ADMIN(true))
    })
  }

  render () {

    const { isAdmin } = this.props

    if (!isAdmin) return <IsAdmin/>

    return <Fragment>
      <Header/>
      <div className="container-fluid">
        <div className="control-panel-add-product">
          <div className="container">
            <MenuTabs/>
            <h2 className={"title text-center"}>Додати новий товар</h2>
            <div className="informer d-none"/>
            <div className="add-product-form">
              <AddProductForm/>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </Fragment>
  }
}

const mapStateToProps = ({ isAdmin }) => {
  return {
    isAdmin: isAdmin,
  }
}

export default connect(mapStateToProps, null)(ControlAddProduct)
