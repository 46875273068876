// Import React Library
import React from "react"
import ScrollButton from "./scrollButton"

export default class Footer extends React.Component {
    render() {
        return <div className="container-fluid">
            <div className="footer">
                <div className="container-fluid">
                    <div className="contact">
                        <div className="d-none d-lg-block d-xl-block">
                        <div className="row text-center">
                                <div className="col-12 col-md-4 col-xl-4">
                                    <p><strong>Контакти</strong></p>
                                    <p>
                                        <strong>Email</strong>
                                        <br/>
                                        mihodi2008@gmail.com
                                    </p>
                                    <p>
                                        <strong>Номер телефону</strong>
                                        <br/>
                                        +380978005541
                                    </p>
                                </div>
                                <div className="col-12 col-md-4 col-xl-4">
                                    <p><strong>Графік роботи</strong></p>
                                    <br/>
                                    <p>
                                        Пн-Пт з 8 до 18
                                        <br/>
                                        Сб-Нд - дзвінки, чат
                                    </p>
                                </div>
                                <div className="col-12  col-md-4 col-xl-4">
                                    <p><strong>Адреса</strong></p>
                                    <br/>
                                    <p>
                                        МИХОДI&trade;
                                        <br/>
                                        Україна Рівненська обл.
                                        <br/> <br/>
                                        35702 м.Здолбунів
                                        <br/>
                                        Старицького, б. 5
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollButton/>
                <button className={"backButton"} onClick={() => window.history.back()}>Назад</button>
            </div>
            <nav className="ncopy justify-content-center">
                © 2008-2021 Всі права захищені Миходi™ (Розробка - <a
                href="https://www.facebook.com/mediankin">MEDIAN</a>)
            </nav>
        </div>
    }
}
