// Import React Library
import React, { Fragment } from "react"
import Header from "../../components/header"
import MenuTabs from "./menu"
import AddCategoryForm from "./forms/addCategoryForm"
import IsAdmin from "./isAdmin"
import { addCategoryAPI, deleteCategoryAPI, isAdminAPI, rateCategoriesAPI } from "../../api"
import { connect } from "react-redux"
import { GET_CATEGORIES_REQUEST, IS_ADMIN } from "../../actions/actionCreator"
import ReactDragListView from "react-drag-listview"

class ControlCategories extends React.Component {

  state = {
    data: null,
    fill: true,
  }

  componentDidMount () {
    document.title = "Панель менеджера - Категории"
    isAdminAPI().then(result => {
      if (result.status) this.props.dispatch(IS_ADMIN(true))
      this.props.dispatch(GET_CATEGORIES_REQUEST())
    })
  }

  componentDidUpdate (prevProps, prevState, snapshot) {}

  DeleteCategory = (id) => {
    deleteCategoryAPI({ id }).then(result => {
      // let informerClass = document.querySelector(".informer")
      // if (result.response.success) {
      //   informerClass.classList.add("success", "animated", "zoomIn")
      //   informerClass.classList.remove("d-none")
      //   informerClass.innerHTML = result.response.success.msg
      //   setTimeout(() => {
      //     informerClass.classList.add("d-none")
      //     informerClass.classList.remove("success", "animated", "zoomIn")
      //   }, 5000)
      //   this.props.dispatch(GET_CATEGORIES_REQUEST())
      // }
      // if (result.response.error) {
      //   informerClass.classList.add("error", "animated", "zoomIn")
      //   informerClass.classList.remove("d-none")
      //   informerClass.innerHTML = result.response.error.msg
      //   setTimeout(() => {
      //     informerClass.classList.add("d-none")
      //     informerClass.classList.remove("success", "animated", "zoomIn")
      //   }, 5000)
      // }
    })
  }

  submit = values => {
    addCategoryAPI(values).then(result => {
        let informerClass = document.querySelector(".informer")
        if (result.response.success) {
          informerClass.classList.add("success", "animated", "zoomIn")
          informerClass.classList.remove("d-none")
          informerClass.innerHTML = result.response.success.msg
          setTimeout(() => {
            informerClass.classList.add("d-none")
            informerClass.classList.remove("success", "animated", "zoomIn")
          }, 5000)
          this.props.dispatch(GET_CATEGORIES_REQUEST())
        }
        if (result.response.error) {
          informerClass.classList.add("error", "animated", "zoomIn")
          informerClass.classList.remove("d-none")
          informerClass.innerHTML = result.response.error.msg
          setTimeout(() => {
            informerClass.classList.add("d-none")
            informerClass.classList.remove("success", "animated", "zoomIn")
          }, 5000)
        }
      },
    )
  }

  render () {

    const { CategoryRequest, CategoryData, isAdmin } = this.props

    if (!isAdmin) return <IsAdmin/>

    if (CategoryRequest) return "Завантаження..."

    let categories = []
    if (CategoryData) categories = CategoryData

    if (categories.length && this.state.fill) this.setState({ data: categories, fill: false })
    if (!this.state.data) return "Завантаження..."

    const that = this
    const dragProps = {
      onDragEnd (fromIndex, toIndex) {
        const { data } = that.state
        const item = data.splice(fromIndex, 1)[0]
        data.splice(toIndex, 0, item)
        that.setState({ data })
        rateCategoriesAPI({ data }).then(result => {
          that.props.dispatch(GET_CATEGORIES_REQUEST())
        })
      },
      nodeSelector: "li",
      handleSelector: "a",
    }

    const ItemCategory = this.state.data.map((item, index) => {
      // eslint-disable-next-line
      return (<li className={"col-12"} key={item._id}><a href="#">{item.name}</a> <span
        onDoubleClick={() => {this.DeleteCategory(item._id)}}>Видалити</span></li>)
    })

    return <Fragment>
      <Header/>
      <div className="container-fluid">
        <div className="control-panel-category">
          <div className="container">
            <MenuTabs/>
            <h2 className={"title text-center"}>Категорії товарів</h2>
            <div className="informer d-none"/>
            <div className="add-category-form">
              <AddCategoryForm onSubmit={this.submit}/>
            </div>
            <div className="categories">
              <ReactDragListView {...dragProps}>
                <ol>{ItemCategory}</ol>
              </ReactDragListView>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  }
}

const mapStateToProps = ({ Category, isAdmin }) => {
  return {
    isAdmin: isAdmin,
    CategoryRequest: Category.request,
    CategoryData: Category.data,
    CategoryError: Category.error,
  }
}

export default connect(mapStateToProps, null)(ControlCategories)
