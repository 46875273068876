// Import React Library
import React, { Fragment } from "react"
import Header from "../../components/header"
import LoginForm from "./forms/loginForm"
import MenuTabs from "./menu"
import { IS_ADMIN } from "../../actions/actionCreator"
import { loginAdminAPI, isAdminAPI } from "../../api"
import { connect } from "react-redux"

class ControlPanel extends React.Component {

  componentDidMount () {
    document.title = "Панель менеджера"
  }

  submit = values => {
    const error = document.querySelector(".error")
    loginAdminAPI(values).then(result => {
      if (result.status) {
        isAdminAPI().then(result => {
          if (result.status) {
            this.props.dispatch(IS_ADMIN(true))
            error.classList.add("d-none")
          }
        })
      } else {
        error.classList.remove("d-none")
        error.innerHTML = "Ошибка проверки данных"
      }
    })
  }

  render () {

    const { isAdmin } = this.props

    if (!isAdmin) return (
      <Fragment>
        <Header/>
        <div className="container-fluid">
          <div className='login-form'>
            <LoginForm onSubmit={this.submit}/>
            <div className="error d-none"/>
          </div>
        </div>
      </Fragment>
    )

    return (
      <Fragment>
        <Header/>
        <div className="container-fluid">
          <div className="control-panel">
            <MenuTabs/>
            <div className='login-form'>
              <div className="text-center"> Добро пожаловать! Выберите пункт для редактирования</div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = ({ isAdmin }) => {
  return {
    isAdmin: isAdmin,
  }
}

export default connect(mapStateToProps, null)(ControlPanel)