import React, { Component, Fragment } from 'react'
import ReactTable from 'react-table'
import 'react-table/react-table.css'
// import './index.scss';

export default class Table extends Component {
  render () {
    return (
      <Fragment>
        <ReactTable
          style={{ marginBottom: '15px', borderRadius: '6px' }}
          //loading={true}
          minRows={0}
          defaultPageSize={20}
          data={this.props.data}
          columns={this.props.columns}
          showPagination={true}
          showPaginationTop={false}
          showPaginationBottom={true}
          pageSizeOptions={[5, 10, 20, 25, 50, 100]}
          filterable={true}
          resizable={true}
          sortable={true}
          multiSort={true}
          getTdProps={this.props.getTdProps}
          previousText={'Попередня'}
          nextText={'Наступна'}
          loadingText={'Loading...'}
          noDataText={'Дані не знайдено'}
          pageText={'Сторінки'}
          ofText={'з'}
          rowsText={''} //на страницу
          pageJumpText={'Перейти до'}
          rowsSelectorText={'рядків на сторінку'}
        />
      </Fragment>
    )
  }
}
