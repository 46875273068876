import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'

class AddManufacturersForm extends Component {

  render () {
    const { handleSubmit, pristine, submitting } = this.props
    return (
      <div>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <Field name="name" component="input" type="text" placeholder="Введіть назву виробника [від 3 до 100 символів]" className={'form-control'}  maxLength="100"/>
          </div>
          <button type="submit" className={'btn btn-main btn-lg btn-block'} disabled={pristine || submitting}>Зберегти</button>
        </form>
      </div>
    )
  }
}

AddManufacturersForm = reduxForm({
  form: 'manufacturers',
})(AddManufacturersForm)

export default AddManufacturersForm
