// Import Axios for API
import ApiRequest from "../helpers/ApiRequest"

const baseUrl = "https://mihodi.rivne.ua"
const prefix = "api"

/***
 * Category API
 * @param data
 * @returns {Promise<T>}
 ***/

export const addCategoryAPI = (data) => {
  return ApiRequest.POST(`${baseUrl}/${prefix}/category/add`, data, {
    withCredentials: true,
  }).then((data) => {
    return data
  })
}

export const rateCategoriesAPI = (data) => {
  return ApiRequest.POST(`${baseUrl}/${prefix}/category/rated`, data, {
    withCredentials: true,
  }).then((data) => {
    return data
  })
}

export const getCategoryAPI = () => {
  return ApiRequest.GET(`${baseUrl}/${prefix}/category/all`).then((data) => {
    return data
  })
}

export const deleteCategoryAPI = (data) => {
  return ApiRequest.POST(`${baseUrl}/${prefix}/category/remove`, data, {
    withCredentials: true,
  }).then((data) => {
    return data
  })
}

/***
 * Manufacturers API
 * @param data
 * @returns {Promise<T>}
 ***/

export const addManufacturersAPI = (data) => {
  return ApiRequest.POST(`${baseUrl}/${prefix}/manufacturers/add`, data, {
    withCredentials: true,
  }).then((data) => {
    return data
  })
}

export const getManufacturersAPI = () => {
  return ApiRequest.GET(`${baseUrl}/${prefix}/manufacturers/all`).then((data) => {
    return data
  })
}

export const rateManufacturersAPI = (data) => {
  return ApiRequest.POST(`${baseUrl}/${prefix}/manufacturers/rated`, data, {
    withCredentials: true,
  }).then((data) => {
    return data
  })
}

export const deleteManufacturersAPI = (data) => {
  return ApiRequest.POST(`${baseUrl}/${prefix}/manufacturers/remove`, data, {
    withCredentials: true,
  }).then((data) => {
    return data
  })
}

/***
 * Products API
 * @param data
 * @returns {Promise<T>}
 ***/

export const addProductsAPI = (data) => {
  return ApiRequest.POST(`${baseUrl}/${prefix}/products/add`, data, {
    withCredentials: true,
  }).then((data) => {
    return data
  })
}

export const getProductsAPI = () => {
  return ApiRequest.GET(`${baseUrl}/${prefix}/products/all`).then((data) => {
    return data
  })
}

export const getLatestProductsAPI = () => {
  return ApiRequest.GET(`${baseUrl}/${prefix}/products/latest`).then((data) => {
    return data
  })
}

export const getProductsByCategoryAPI = (data) => {
  return ApiRequest.POST(`${baseUrl}/${prefix}/products/category`, data, {
    withCredentials: true,
  }).then((data) => {
    return data
  })
}

export const getProductsByManufacturersAPI = (data) => {
  return ApiRequest.POST(`${baseUrl}/${prefix}/products/manufacturers`, data, {
    withCredentials: true,
  }).then((data) => {
    return data
  })
}

export const getProductsBySearchAPI = (data) => {
  return ApiRequest.POST(`${baseUrl}/${prefix}/products/search`, data, {
    withCredentials: true,
  }).then((data) => {
    return data
  })
}

export const getSimilarProductsAPI = (data) => {
  return ApiRequest.POST(`${baseUrl}/${prefix}/products/similar`, data, {
    withCredentials: true,
  }).then((data) => {
    return data
  })
}

export const getTopProductsAPI = () => {
  return ApiRequest.GET(`${baseUrl}/${prefix}/products/top`).then((data) => {
    return data
  })
}

export const getStockProductsAPI = () => {
  return ApiRequest.GET(`${baseUrl}/${prefix}/products/stock`).then((data) => {
    return data
  })
}

export const getSingleProductsAPI = (data) => {
  return ApiRequest.POST(`${baseUrl}/${prefix}/products/single`, data).then((data) => {
    return data
  })
}

export const editProductsAPI = (data) => {
  return ApiRequest.POST(`${baseUrl}/${prefix}/products/edit`, data, {
    withCredentials: true,
  }).then((data) => {
    return data
  })
}

export const deleteProductsAPI = (id) => {
  return ApiRequest.POST(`${baseUrl}/${prefix}/products/remove`, id, {
    withCredentials: true,
  }).then((data) => {
    return data
  })
}

/***
 * Login API
 * @param data
 * @returns {Promise<T>}
 ***/

export const loginAdminAPI = (data) => {
  return ApiRequest.POST(`${baseUrl}/${prefix}/login/admin`, data, {
    withCredentials: true,
  }).then((data) => {
    return data
  })
}

export const isAdminAPI = () => {
  return ApiRequest.GET(`${baseUrl}/${prefix}/login/isAdmin`, {
    withCredentials: true,
  }).then((data) => {
    return data
  })
}

export const logOutAPI = () => {
  return ApiRequest.GET(`${baseUrl}/${prefix}/login/logout`, {
    withCredentials: true,
  }).then((data) => {
    return data
  })
}
