import { call, put } from 'redux-saga/effects'
import { GET_CATEGORIES_RESPONSE, GET_CATEGORIES_ERROR } from '../actions/actionCreator'
import { getCategoryAPI } from '../api'
// eslint-disable-next-line
export function * categorySaga () {
  try {
    yield put(GET_CATEGORIES_RESPONSE({ request: true }))
    const data = yield call(getCategoryAPI)
    yield put(GET_CATEGORIES_RESPONSE({ request: false, data: data, error: false }))
  } catch (error) {
    yield put(GET_CATEGORIES_ERROR({ request: true, error: error }))
  }
}
