// Import React Library
import React from "react"
// eslint-disable-next-line
export default () => {
    return <div className={"d-none d-lg-block d-xl-block mainBg "}>
        <div className="preview">
            <div className="title">Миході - твоя улюблена ковбаска</div>
        </div>
    </div>
}
