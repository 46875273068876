// Import React Library
import React from 'react'
// eslint-disable-next-line
export default () => {
    return <div className="container-fluid">
        <div className="container">
            <div className="loader"/>
        </div>
    </div>
}
