import { combineReducers } from "redux"
import { reducer as formReducer } from "redux-form"
import Category from "./categories"
import activeCategory from "./activeCategory"
import Manufacturers from "./manufacturers"
import activeManufacturers from "./activeManufacturers"
import Products from "./products"
import latestProducts from "./latestProducts"
import topProducts from "./topProducts"
import stockProducts from "./stockProducts"
import ProductsFilter from "./filterProducts"
import Product from "./product"
import ProductFilter from "./filterProduct"
import Filters from "./filters"
import LastProduct from "./lastProduct"
import ProductEdit from "./editProduct"
import isAdmin from "./isAdmin"
// eslint-disable-next-line
export default combineReducers({
  form: formReducer,
  Category,
  activeCategory,
  Manufacturers,
  activeManufacturers,
  Products,
  latestProducts,
  topProducts,
  stockProducts,
  ProductsFilter,
  Product,
  ProductFilter,
  Filters,
  LastProduct,
  ProductEdit,
  isAdmin,
})
