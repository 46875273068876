// Import React Library
import React, {Component} from "react"
import {NavLink} from "react-router-dom"
import {connect} from "react-redux"
import {GET_CATEGORIES_REQUEST} from "../../../actions/actionCreator"

class CategoriesBlock extends Component {

    componentDidMount() {
        this.props.dispatch(GET_CATEGORIES_REQUEST())
    }

    render() {
        const {Category} = this.props
        if (!Category) return "Завантаження"

        const Item = Category.map((item) => {
            return <div className="col-12 col-md-4 col-lg-3" key={item._id}>
                <NavLink to={`/category/${item.translit}`} exact={true} activeClassName="active">
                    <div className="card">
                        <img className="card-img-top" src={`./new_menu/${item.translit}.jpg`} alt={item.name}/>
                        <div className="card-body">
                            <h5 className="card-title">{item.name}</h5>
                        </div>
                    </div>
                </NavLink>
            </div>
        })

        return <div className="container-fluid" style={{width: "95%"}}>
            <div className="categoriesBlock">
                <div className="row">
                    <div className="card-deck">{Item}</div>
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = ({Category}) => {
    return {
        CategoryRequest: Category.request,
        Category: Category.data,
        CategoryError: Category.error,
    }
}

export default connect(mapStateToProps, null)(CategoriesBlock)
