import React, { Component, Fragment, lazy, Suspense } from "react"
import { NavLink } from "react-router-dom"
import { connect } from "react-redux"
import { GET_TOP_PRODUCTS_REQUEST } from "../../../actions/actionCreator"
import { SmallLoader } from "../../../components/preloader"
import Loader from "../../loader"

export const Header = lazy(() => import("../../../components/header"))
export const Menu = lazy(() => import("../../../components/menu"))
export const Footer = lazy(() => import("../../../components/footer"))

class TopProducts extends Component {

  componentDidMount () {
    this.props.dispatch(GET_TOP_PRODUCTS_REQUEST())
    document.title = "Лучшая мясная и колбасная продукция Миходi™"
    document.getElementsByTagName(
      "META")[3].content = "Лучшая мясная и колбасная продукция Миходi™. Купить оптом, Украина"
    document.getElementsByTagName("META")[4].content = "Миходi, Рiвне, лучшая продукция, купить колбасы, колбасные и мясные изделия оптом, колбаса оптом, колбаса цена"
  }

  render () {
    const { topProducts } = this.props

    if (!topProducts) return <Loader/>

    const Item = topProducts.map((item) => {
      return (
        <div className={"col-12 col-md-4 col-lg-3"} key={item._id}>
          <div itemScope itemType="http://schema.org/ItemList">
            <div itemProp="itemListElement" itemScope itemType="http://schema.org/Product">
              <NavLink itemProp="url" to={`/product/${item.category}/${item.manufacturers}/${item.translit}/`}>
                <div className={item.status ? "card text-center" : "card text-center grayscale"}>
                  <picture>
                    <source srcSet={`/uploads/${item.category}/${item.translit}/${item.translit}_1.webp`} type="image/webp"/>
                    <source srcSet={`/uploads/${item.category}/${item.translit}/${item.translit}_1.png`} type="image/png"/>
                    <img src={`/uploads/${item.category}/${item.translit}/${item.translit}_1.webp`} alt={item.translit} className="card-img-top"
                         itemProp="image"/>
                  </picture>
                  <div className="card-body">
                    <div className="card-title text-center"><span itemProp="manufacturer">{item.manufacturers_cyr}</span>
                      <br/>
                      "<span itemProp="name">{item.name}"</span></div>
                  </div>
                  <div className="card-footer">
                    <div className="text-center">
                      <div itemProp="offers" itemScope itemType="http://schema.org/Offer">
                        {item.status
                          ? <div>{item.price ? <div><strong itemProp="price" content={item.price}>{item.price}</strong> грн</div> :
                            <div>Уточнюйте по телефону</div>}</div>
                          : "Немає в наявності"}
                      </div>
                    </div>
                  </div>
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      )
    })

    return <Fragment>
      <Suspense fallback={<SmallLoader/>}>
        <Header/>
      </Suspense>
      <Suspense fallback={<SmallLoader/>}>
        <Menu/>
      </Suspense>
      <div className="container-fluid mrg">
        <div className={"top"}>
          <h2 className="title  text-center">Топ продажів</h2>
          <div className="row">
            <div className="card-deck">
              {Item}
            </div>
          </div>
        </div>
      </div>
      <Suspense fallback={<SmallLoader/>}>
        <Footer/>
      </Suspense>
    </Fragment>
  }
}

const mapStateToProps = ({ topProducts }) => {
  return {
    topProductsRequest: topProducts.request,
    topProducts: topProducts.data,
    topProductsError: topProducts.error,
  }
}

export default connect(mapStateToProps, null)(TopProducts)
