import { call, put, select } from 'redux-saga/effects'
import { GET_PRODUCT_RESPONSE, GET_PRODUCT_ERROR } from '../actions/actionCreator'
import { getSingleProductsAPI } from '../api'

export function * productSaga () {
  try {
    const productFilter = (state) => state.ProductFilter
    const filter = yield select(productFilter)
    yield put(GET_PRODUCT_RESPONSE({ request: true }))
    const fetch = async () => {
      return await getSingleProductsAPI(filter)
    }
    const data = yield call(fetch)
    yield put(GET_PRODUCT_RESPONSE({ request: false, data: data, error: false }))
  } catch (error) {
    yield put(GET_PRODUCT_ERROR({ request: true, error: error }))
  }
}