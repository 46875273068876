// Import React Library
import React, { Fragment } from "react"
import { connect } from "react-redux"
import { NavLink } from "react-router-dom"
import Header from "../../components/header"
import MenuTabs from "./menu"
import Table from "../../components/table"
import EditProductForm from "./forms/editProductForm"
import { IS_ADMIN } from "../../actions/actionCreator"
import { GET_PRODUCTS_REQUEST, PRODUCT_EDIT } from "../../actions/actionCreator"
import IsAdmin from "./isAdmin"
import { isAdminAPI } from "../../api"

class ControlEditProduct extends React.Component {

  componentDidMount() {
    document.title = "Панель менеджера - Редактировать продукт"
    isAdminAPI()
      .then(result => {
        if (result.status) {
          this.props.dispatch(IS_ADMIN(true))
          this.props.dispatch(GET_PRODUCTS_REQUEST())
        }
      })
  }

  EditForm = (id, data) => {
    const arr = data.filter((data) => data._id === id)
      .map(function (data, index, keys) {
        return { ...data }
      })
    this.props.dispatch(PRODUCT_EDIT({
      product: arr,
      form: true
    }))
  }

  render() {

    const { ProductsData, ProductEdit, isAdmin } = this.props

    if (!isAdmin) return <IsAdmin/>

    const columns = [
      {
        Header: "Товар",
        accessor: "name",
        headerStyle: { whiteSpace: "unset" },
        style: { whiteSpace: "unset" },
        id: "name",
      },
      {
        Header: "Цена",
        accessor: "price",
        sortMethod: (a, b) => Number(a) - Number(b),
        headerStyle: { whiteSpace: "unset" },
        style: {
          whiteSpace: "unset",
          textAlign: "center"
        },
        maxWidth: 80,
      },
      {
        Header: "Код",
        accessor: "code",
        headerStyle: { whiteSpace: "unset" },
        style: {
          whiteSpace: "unset",
          textAlign: "center"
        },
      },
      {
        Header: "Акция",
        accessor: "stock",
        Cell: ({ value }) => (value ? "Да" : ""),
        headerStyle: { whiteSpace: "unset" },
        style: {
          whiteSpace: "unset",
          textAlign: "center"
        },
        maxWidth: 70,
        Filter: ({ filter, onChange }) =>
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : ""}
          >
            <option value="">Все</option>
            <option value="false">Нет</option>
            <option value="true">Да</option>
          </select>,
      },
      {
        Header: "Топ",
        accessor: "top",
        Cell: ({ value }) => (value ? "Да" : ""),
        headerStyle: { whiteSpace: "unset" },
        style: {
          whiteSpace: "unset",
          textAlign: "center"
        },
        maxWidth: 70,
        Filter: ({ filter, onChange }) =>
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : ""}
          >
            <option value="">Все</option>
            <option value="false">Нет</option>
            <option value="true">Да</option>
          </select>,
      },
      {
        Header: "Новинка",
        accessor: "latest",
        Cell: ({ value }) => (value ? "Да" : ""),
        headerStyle: { whiteSpace: "unset" },
        style: {
          whiteSpace: "unset",
          textAlign: "center"
        },
        maxWidth: 70,
        Filter: ({ filter, onChange }) =>
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : ""}
          >
            <option value="">Все</option>
            <option value="false">Нет</option>
            <option value="true">Да</option>
          </select>,
      },
      {
        Header: "Наличие",
        accessor: "status",
        Cell: ({ value }) => (value ? "" : "Нет"),
        headerStyle: { whiteSpace: "unset" },
        style: {
          whiteSpace: "unset",
          textAlign: "center"
        },
        maxWidth: 70,
        Filter: ({ filter, onChange }) =>
          <select
            onChange={event => onChange(event.target.value)}
            style={{ width: "100%" }}
            value={filter ? filter.value : ""}
          >
            <option value="">Все</option>
            <option value="false">Нет</option>
            <option value="true">Да</option>
          </select>,
      },
      {
        Header: "Категория",
        accessor: "category_cyr",
        headerStyle: { whiteSpace: "unset" },
        style: { whiteSpace: "unset" },
      },
      {
        Header: "Производитель",
        accessor: "manufacturers_cyr",
        headerStyle: { whiteSpace: "unset" },
        style: { whiteSpace: "unset" },
      },
    ]

    const data = ProductsData

    const ProductsTable = <Table data={data} columns={columns} filterable
                                 style={{
                                   height: "400px",
                                 }} getTdProps={(state, rowInfo) => {
      return {
        onDoubleClick: () => {
          this.EditForm(rowInfo.original._id, data)
        }
      }
    }}/>

    const EditForm = <EditProductForm/>

    return (
      <Fragment>
        <Header/>
        <div className="container-fluid">
          {/*<div className="control-panel">*/}
          <div className="control-panel-edit-product">
            <MenuTabs/>
            <div className="col">
              <div className={"title text-center"}>
                <h2 style={{ display: "inline-block" }}>Редактировать товар</h2> - <NavLink
                to={"/control-panel/price"}><h2 style={{ display: "inline-block" }}>CSV</h2>
              </NavLink>
              </div>
              <div className="informer d-none text-center"/>
              {(ProductEdit.form ?
                <div className="edit-product-form">{EditForm}</div> : ProductsTable)}
            </div>
          </div>
        </div>
        {/*</div>*/}
      </Fragment>
    )
  };
}

const mapStateToProps = ({ Products, ProductEdit, isAdmin }) => {
  return {
    isAdmin: isAdmin,
    ProductsRequest: Products.request,
    ProductsData: Products.data,
    ProductsError: Products.error,
    ProductEdit: ProductEdit,
  }
}

export default connect(mapStateToProps, null)(ControlEditProduct)
