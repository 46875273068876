const initialState = {
  request: false,
  data: [],
  error: false,
}
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PRODUCTS_REQUEST':
      return {
        ...state,
        request: true,
        error: false,
      }
    case 'GET_PRODUCTS_RESPONSE':
      return {
        ...state,
        request: false,
        data: action.payload.data,
        error: false,
      }
    case 'GET_PRODUCTS_ERROR':
      return {
        ...state,
        request: true,
        error: action.payload,
      }
    default:
      return state
  }
};
export const productsRequest = state => state.request
export const productsData = state => state.data
export const productsError = state => state.error
