// Import React Library
import React, {Component} from "react"

export default class MainAbout extends Component {
    render() {
        return <div className="about__us">
            <div className="content">
                <h1 className="text-center">Чому Миходi?</h1>
                <div className='why-us'>
                    <div className="row">
                        <div className="col-12 col-md-3 text-center" style={{margin: '10px 0'}}>
                            <div className="text-center" style={{margin: '20px 0'}}>
                                <img src={"./images/salami.svg"} alt="" height={"50"}/>
                            </div>
                            <strong style={{fontSize: "36px"}}>Продукція від 50 виробників</strong>
                        </div>
                        <div className="col-12 col-md-3 text-center" style={{margin: '10px 0'}}>
                            <div className="text-center" style={{margin: '20px 0'}}>
                                <img src={"./images/stand.svg"} alt="" height={"50"}/>
                            </div>
                            <strong style={{fontSize: "36px"}}>12 років якісних послуг в Україні</strong>
                        </div>
                        <div className="col-12 col-md-3 text-center" style={{margin: '10px 0'}}>
                            <div className="text-center" style={{margin: '20px 0'}}>
                                <img
                                    src={"./images/moving-truck.svg"} alt="" height={"50"}/>
                            </div>
                            <strong style={{fontSize: "36px"}}>Більше 1000 товарів в асортименті</strong>
                        </div>
                        <div className="col-12 col-md-3 text-center" style={{margin: '10px 0'}}>
                            <div className="text-center" style={{margin: '20px 0'}}>
                                <img src={"./images/knife.svg"} alt="" height={"50"}/>
                            </div>
                            <strong style={{fontSize: "36px"}}>Свіжий товар протягом усього тижня</strong>
                        </div>
                    </div>
                </div>

                <div className='more'>
                    <p className="text-center"><strong>Миходi &trade; - офіційний представник понад 50 найбільших виробників зі
                        всієї України.</strong></p>

                    <p>Ми успішно реалізуємо м'ясну продукцію починаючи з 2008 року. Завдяки перевіреним підприємствам і
                        регулярним поставкам впродовж
                        усього тижня наші товари завжди свіжі і якісні.</p>

                    <p>За час існування ми встигли завоювати довіру сотень постійних оптових клієнтів. Наш асортимент
                        включає більше 1000 позицiй та
                        постiйно оновлюється.</p>

                    <p>У своїй роботі Миходi™ керується строгими правилами та дотримується стандартiв харчової етики,
                        безпеки і прозорості на всіх
                        етапах.</p>

                    <p>Доставка товарів здійснюється спеціалізованим транспортом з холодильними установками. Наші
                        співробітники - професіонали своєї
                        справи. Вони підготують все так, як Вам потрібно, і Ви отримаєте товар без зайвих турбот. До
                        того ж, Миходi™ має власну мережу
                        магазинів, і ми готові в індивідуальному порядку зробити аналіз і рекомендувати продукцію для
                        початківців. Наш багаторічний досвід
                        допоможе Вам на стартi власного бізнесу.</p>

                    <p>А завдяки нашому інтернет-магазину Ви можете постійно бути в курсі всього асортименту, цін та
                        наявності продукції, а також знижок,
                        і ми знаємо, як це важливо. А ще, у нас дуже хороша система індивідуальних знижок для кожного
                        оптового клієнта. Цікаво? Тоді
                        телефонуйте, і ми обговоримо деталі. Співпраця з нами принесе Вам позитивні емоції, і не тільки
                        ;)</p>

                    <p>Якщо Ви знайшли бажані товари в нашому асортименті або хотіли б отримувати доставку в місто,
                        якого нема у розділі "Доставка",
                        повідомте нам, і ми це виправимо. Наше головне завдання - щоб Ви і Ваші покупці були задоволені,
                        адже це запорука успішної співпраці
                        і головна мета нашої компанії.</p>
                </div>
            </div>
        </div>
    }
}
